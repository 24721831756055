import styled from 'styled-components';
import { Col } from 'antd';

export const Container = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
`;

export const ImagesContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin-bottom: 15px;
`;

export const ImagePairContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 20px;
`;

export const Image = styled.img`
	width: 100px;
`;
