import React from 'react';
import { AntIcon, Loading } from './styles';

interface SpinnerProps {
	size?: number | 38;
}

export const Spinner: React.FC<SpinnerProps> = ({ size }: Readonly<SpinnerProps>) => (
	<Loading indicator={<AntIcon size={size} spin />} />
);
