import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row } from 'antd';

import { ExertionDuration } from './ExertionDuration';
import { InputSection } from '../InputSection';
import { BorgScale } from './BorgScale';
import { Efforts } from './Efforts';

export function RiskFactors1({ form }) {
	const [durationPercentage, setDurationPercentage] = useState({ left: 0, right: 0 });
	const [isPercentageValid, setIsPercentageValid] = useState({ left: false, right: false });

	function onChangeFactors() {
		const sides = ['left', 'right'];

		const percentages = sides.map((side) => {
			const observationTime = form.getFieldValue('input_' + side + '_observation_time');
			const exertions = form.getFieldValue('input_' + side + '_exertions');
			const exertionDuration = form.getFieldValue('input_' + side + '_exertion_duration');

			if (observationTime == null || exertions == null || exertionDuration == null) {
				return;
			}

			const maxExertions = observationTime / exertions;

			const percentage = exertionDuration / maxExertions;
			const roundedPercentage = Math.round(percentage * 10000) / 10000;

			return roundedPercentage;
		});

		setDurationPercentage({
			left: percentages[0],
			right: percentages[1]
		});
		setIsPercentageValid({
			left: percentages[0] <= 1,
			right: percentages[1] <= 1
		});
	}

	function initPercentages() {
		const fieldsValue = {
			left: form.getFieldsValue([
				'input_left_observation_time',
				'input_left_exertions',
				'input_left_exertion_duration'
			]),
			right: form.getFieldsValue([
				'input_right_observation_time',
				'input_right_exertions',
				'input_right_exertion_duration'
			])
		};
		const isLeftFilled = Object.values(fieldsValue.left).every((property) => property != null);
		const isRightFilled = Object.values(fieldsValue.right).every((property) => property != null);

		if (isLeftFilled || isRightFilled) {
			onChangeFactors();
		}
	}

	useEffect(() => {
		initPercentages();
	}, []);

	return (
		<Row justify="center" align="middle">
			<InputSection title={I18n.get('Intensity of exertion (Borg Scale - BS)')} children={<BorgScale />} />
			<InputSection
				title={I18n.get('Efforts per minute')}
				children={<Efforts onChangeFactors={onChangeFactors} />}
			/>
			<InputSection
				title={I18n.get('Duration per exertion')}
				children={
					<ExertionDuration
						durationPercentage={durationPercentage}
						isPercentageValid={isPercentageValid}
						onChangeFactors={onChangeFactors}
					/>
				}
			/>
		</Row>
	);
}
