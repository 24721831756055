import React from 'react';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';
import { Col, Tag, Typography } from 'antd';

import { ClassificationGauge } from '@/components/ui/Reports/ClassificationGauge';

const { Text } = Typography;

const Container = styled(Col)`
	display: flex;
	justify-content: center;
`;

const SideContainer = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-inline: 60px;
`;

const SideTitle = styled.h1`
	font-weight: bold;
`;

const GaugeContainer = styled(Col)`
	width: 150px;
`;

const ResultLabel = styled(Tag)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	margin-top: 10px;
	width: fit-content;
	min-width: 60px;
	height: 30px;
	font-weight: bold;
	font-size: 1.2rem;
`;

const ResultText = styled(Text)`
	margin-top: 10px;
`;

export const Conclusion = ({ formattedReport }) => {
	const safe = {
		left: formattedReport.left_risk === 'SAFE',
		right: formattedReport.right_risk === 'SAFE'
	};

	const descriptions = {
		safe: I18n.get('Job is probably safe'),
		hazardous: I18n.get('Job is probably hazardous')
	};

	const left = {
		color: safe.left ? 'green' : 'red',
		text: safe.left ? descriptions.safe : descriptions.hazardous,
		type: safe.left ? 'success' : 'danger',
		classification: safe.left ? 'low' : 'high'
	};

	const right = {
		color: safe.right ? 'green' : 'red',
		text: safe.right ? descriptions.safe : descriptions.hazardous,
		type: safe.right ? 'success' : 'danger',
		classification: safe.right ? 'low' : 'high'
	};

	return (
		<Container>
			<SideContainer>
				<SideTitle>{I18n.get('Left')}</SideTitle>
				<GaugeContainer>
					<ClassificationGauge classification={left.classification} />
				</GaugeContainer>
				<ResultLabel color={left.color}>{formattedReport.score_left_rsi}</ResultLabel>
				<ResultText strong type={left.type}>
					{left.text}
				</ResultText>
			</SideContainer>
			<SideContainer>
				<SideTitle>{I18n.get('Right')}</SideTitle>
				<GaugeContainer>
					<ClassificationGauge classification={right.classification} />
				</GaugeContainer>
				<ResultLabel color={right.color}>{formattedReport.score_right_rsi}</ResultLabel>
				<ResultText strong type={right.type}>
					{right.text}
				</ResultText>
			</SideContainer>
		</Container>
	);
};
