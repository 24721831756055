import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	Container,
	CardsContainer,
	InfoCard,
	TitleContainer,
	Input,
	Title,
	Description
} from './styles';
import { I18n } from '@aws-amplify/core';

const defaultCardStyle = {
	width: '100%',
	height: 'auto'
};

export function RadioCards({
	name,
	cards,
	cardType = 'horizontal',
	direction = 'column',
	cardStyle = defaultCardStyle,
	...restProps
}) {
	const [selectedCard, setSelectedCard] = useState(restProps.checked ?? cards[0]);

	function onRadioSelect(event) {
		setSelectedCard(event.target.value);
		restProps.onChange(event.target.value);
	}

	function onCardSelect(value) {
		setSelectedCard(value);
		restProps.onChange(value);
	}

	return (
		<Container>
			<CardsContainer direction={direction}>
				{Object.keys(cards).map((card) => (
					<InfoCard
						key={card}
						selected={selectedCard === card}
						style={cardStyle}
						cardType={cardType}
						onClick={() => onCardSelect(card)}
					>
						<TitleContainer cardType={cardType}>
							<Input
								type="radio"
								name={name}
								id={card}
								value={card}
								checked={selectedCard === card || restProps.checked === card}
								onChange={onRadioSelect}
							/>
							<Title>{I18n.get(cards[card].title)}</Title>
						</TitleContainer>
						<Description cardType={cardType}>
							{I18n.get(cards[card].description)}
						</Description>
					</InfoCard>
				))}
			</CardsContainer>
		</Container>
	);
}

RadioCards.propTypes = {
	name: PropTypes.string.isRequired,
	cards: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string
	}).isRequired,
	cardType: PropTypes.oneOf(['horizontal', 'vertical']),
	direction: PropTypes.oneOf(['column', 'row']),
	cardStyle: PropTypes.object
};
