import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import {
	CheckCircleOutlined,
	ExclamationCircleOutlined,
	CloseCircleOutlined
} from '@ant-design/icons';

const { Text } = Typography;

export const Title = styled(Text)`
	font-size: 1rem;
	font-weight: bold;
`;

export const Description = styled(Text)`
	font-size: 0.8rem;
`;

export const Container = styled(Col)`
	margin-top: 20px;
`;

export const InnerContainer = styled(Row)`
	margin-top: 20px;
`;

export const Check = styled(CheckCircleOutlined)`
	color: #52c41a;
	font-size: 1.3rem;
	margin: 2px;
`;
export const Close = styled(CloseCircleOutlined)`
	color: #ff4170;
	font-size: 1.3rem;
	margin: 2px;
`;
export const Exclamation = styled(ExclamationCircleOutlined)`
	color: #fdc365;
	font-size: 1.3rem;
	margin: 2px;
`;

export const ErrorMessage = styled.span`
	font-size: 0.8rem;
	font-weight: bolder;
	color: darkred;
`;
