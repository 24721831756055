import React from 'react';
import { I18n } from '@aws-amplify/core';

import { Container, ParameterCard } from './styles';

import { DIAGNOSTICS } from '@/components/views/Report/KimManualHandling/enum.js';

export function Diagnostics({ report }) {
	const risk = report.data.risk_load;

	const parameters = [
		{
			title: 'Probability of physical overload',
			value: DIAGNOSTICS[risk].PHYSICAL_OVERLOAD
		},
		{
			title: 'Possible health consequences',
			value: DIAGNOSTICS[risk].HEALTH_CONSEQUENCES
		},
		{
			title: 'Measures',
			value: DIAGNOSTICS[risk].MEASURES
		}
	];

	return (
		<Container>
			{parameters.map((parameter, index) => (
				<ParameterCard
					key={index}
					title={I18n.get(parameter.title)}
					headStyle={{ fontWeight: 'bold' }}
				>
					<p>{I18n.get(parameter.value)}</p>
				</ParameterCard>
			))}
		</Container>
	);
}
