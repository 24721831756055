import styled from 'styled-components';

export const Image = styled.img`
	width: 80px;
`;

export const SubTitle = styled.span`
	font-weight: bold;
`;

export const WrapperCard = styled.div`
	padding: 10px;
	border-style: ${(props) => (props.isButton ? 'solid' : '')};
	border-width: ${(props) => (props.isButton ? '0.5px' : '')};
	border-color: ${(props) => (props.isButton ? '#e0e0e0' : '')};
	height: ${(props) => props.height};
	background-color: ${(props) => (props.isPosture ? '' : 'white')};
	border-radius: ${(props) => (props.isPosture ? '10px' : '5px')};
	box-shadow: ${(props) =>
		props.isPosture ? '0px 5px 10px rgba(174, 182, 183, 0.25)' : ''};
	cursor: ${(props) =>
		props.isButton || props.isPosture ? (props.onClick ? 'pointer' : 'default') : ''};
	transition: box-shadow 0.2s ease, border 0.2s ease, color 0.2s ease;
	&:hover {
		box-shadow: ${(props) =>
			props.isButton || props.isPosture
				? props.onClick
					? '2px 2px 10px lightgrey'
					: '2px 2px 5px lightgrey'
				: ''};
		border: ${(props) =>
			props.isButton || props.isPosture
				? props.onClick
					? '1px solid #5CD3F8'
					: 'none'
				: ''};
	}
	&.active {
		box-shadow: ${(props) =>
			props.isButton || props.isPosture
				? props.onClick
					? '2px 2px 10px lightgrey'
					: '2px 2px 5px lightgrey'
				: ''};
		border: ${(props) =>
			props.isButton || props.isPosture
				? props.onClick
					? '1px solid #5CD3F8'
					: 'none'
				: ''};
	}
`;

export const ButtonCard = styled.div`
	padding: 10px 0 10px 10px;
	margin-bottom: 15px;
	border-style: solid;
	border-width: 0.5px;
	border-color: #e0e0e0;
	white-space: initial;
	text-align: left;
	float: left;
	width: 100%;
	cursor: ${(props) => (props.isButton ? (props.onClick ? 'pointer' : 'default') : '')};
	transition: box-shadow 0.2s ease, border 0.2s ease, color 0.2s ease;
	&:hover {
		box-shadow: ${(props) =>
			props.isButton
				? props.onClick
					? '2px 2px 10px lightgrey'
					: '2px 2px 5px lightgrey'
				: ''};
		border: ${(props) =>
			props.isButton ? (props.onClick ? '1px solid #5CD3F8' : 'none') : ''};
	}
	&.active {
		box-shadow: ${(props) =>
			props.isButton
				? props.onClick
					? '2px 2px 10px lightgrey'
					: '2px 2px 5px lightgrey'
				: ''};
		border: ${(props) =>
			props.isButton ? (props.onClick ? '1px solid #5CD3F8' : 'none') : ''};
	}
`;
