import React from 'react';

import { LibertyMutualProvider } from './context';
import { Report } from './Report';

export const LibertyMutual = () => {
	return (
		<LibertyMutualProvider>
			<Report />
		</LibertyMutualProvider>
	);
};
