import React from 'react';
import { I18n } from '@aws-amplify/core';

import { Container, Label, Parameter, Value } from './styles';

import {
	FORCE_INTENSITIES,
	FORCE_TRANSFERS
} from '@/components/views/Report/KimManualHandling/enum.js';

export function InputParameters({ report }) {
	const parameters = [
		{
			label: 'Total duration per working day',
			value: report.data.duration,
			suffix: 'hours'
		},
		{
			label: 'Type of force exertion in the finger/hand (left | right)',
			value:
				I18n.get(FORCE_INTENSITIES[report.data.left_force_intensity].title) +
				' | ' +
				I18n.get(FORCE_INTENSITIES[report.data.right_force_intensity].title),
			suffix: null
		},
		{
			label: 'Force transfer / gripping conditions',
			value: FORCE_TRANSFERS[report.data.force_transfer].title,
			suffix: null
		}
	];

	return (
		<Container>
			{parameters.map((parameter, index) => (
				<Parameter key={index}>
					<Label>{I18n.get(parameter.label)}:</Label>
					<Value>
						{I18n.get(parameter.value)} {I18n.get(parameter.suffix)}
					</Value>
				</Parameter>
			))}
		</Container>
	);
}
