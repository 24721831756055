import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, InputNumber } from 'antd';

import {
	ColorScale,
	Container,
	Description,
	GridContainer,
	InfoContainer,
	InputLabel,
	Label,
	LabelsContainer,
	LeftHeader,
	LeftInput,
	numberInputStyle,
	RightHeader,
	RightInput
} from './styles';

export const BorgScale = () => {
	const borgScale = [
		{
			title: I18n.get('Light'),
			description: I18n.get('Barely noticeable or relaxed effort (BS: 0-2)'),
			max_value: 2
		},
		{
			title: I18n.get('Somewhat Hard'),
			description: I18n.get('Noticeable or definite effort (BS: 3)'),
			max_value: 3
		},
		{
			title: I18n.get('Hard'),
			description: I18n.get('Obvious effort; Unchanged facial expression (BS: 4-5)'),
			max_value: 5
		},
		{
			title: I18n.get('Very Hard'),
			description: I18n.get('Substantial effort; Changes expression (BS: 6-7)'),
			max_value: 7
		},
		{
			title: I18n.get('Near Maximal'),
			description: I18n.get('Uses shoulder or trunk for force (BS: 8-10)'),
			max_value: 10
		}
	];

	return (
		<Container>
			<InfoContainer>
				<ColorScale />
				<LabelsContainer>
					{borgScale.map((item) => (
						<Col
							style={{
								display: 'flex',
								justifyContent: 'left',
								alignItems: 'center'
							}}
							key={item.title}
						>
							<Label>{item.title}</Label>
							<Description>{item.description}</Description>
						</Col>
					))}
				</LabelsContainer>
			</InfoContainer>
			<GridContainer span={24}>
				<LeftHeader>{I18n.get('Left')}</LeftHeader>
				<RightHeader>{I18n.get('Right')}</RightHeader>

				<InputLabel>{I18n.get('Borg Scale (0-10)')}:</InputLabel>
				<LeftInput
					name="input_left_borg_scale"
					rules={[
						{
							required: true,
							message: false
						}
					]}
				>
					<InputNumber style={numberInputStyle} min={0} max={10} step={1} placeholder={0} />
				</LeftInput>
				<RightInput
					name="input_right_borg_scale"
					rules={[
						{
							required: true,
							message: false
						}
					]}
				>
					<InputNumber style={numberInputStyle} min={0} max={10} step={1} placeholder={0} />
				</RightInput>
			</GridContainer>
		</Container>
	);
};
