import styled from 'styled-components';
import { Col, Row, Tag, Typography } from 'antd';
import { ManOutlined, WomanOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;
`;

export const Section = styled.section`
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
`;

export const InsideContainer = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	margin-top: 30px;
	margin-bottom: 10px;
`;

export const GaugeContainer = styled(Col)`
	width: 130px;
`;

export const ResultLabel = styled(Tag)`
	font-weight: bold;
	font-size: 1rem;
	margin: 0 auto;
	padding: 2px 15px;
`;

export const ResultText = styled(Text)`
	margin-bottom: 10px;
`;

export const InputTitle = styled(Text)`
	font-weight: bold;
	margin-bottom: 10px;
	font-size: 0.8rem;
`;

export const InputContent = styled.div`
	margin-bottom: 10px;
	font-size: 0.8rem;
`;

export const InputRow = styled(Row)`
	margin-bottom: 10px;
`;

// AJUSTAR CORES:
export const Man = styled(ManOutlined)`
	color: lightblue;
	font-size: 4rem;
`;

export const Woman = styled(WomanOutlined)`
	color: pink;
	font-size: 4rem;
`;

export const User = styled(UserOutlined)`
	color: #a9a9a9;
	font-size: 4rem;
`;

export const Team = styled(TeamOutlined)`
	color: #a9a9a9;
	font-size: 4rem;
`;
