import styled from 'styled-components';
import { Card, Col } from 'antd';

export const Container = styled(Col)`
	display: flex;
	justify-content: space-evenly;
	align-items: stretch;
	width: 100%;
`;

export const ParameterCard = styled(Card)`
	width: 30%;

	.ant-card-head,
	.ant-card-body {
		font-size: 13px;
	}

	.ant-card-head-title {
		overflow: auto;
		white-space: normal;
	}
`;
