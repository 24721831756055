import { Col, Row } from 'antd';
import React from 'react';
import {
	Frequency,
	TaskImage,
	HandCoupling,
	ObjectWeight,
	EndHandHeight,
	EndHandDistance,
	StartHandHeight,
	StartHandDistance
} from './Fields';

export const LiftAndLowerForm = () => {
	return (
		<Col xs={24}>
			<Row justify="space-between" align="middle">
				<Col xs={7}>
					<TaskImage width="80%" />
				</Col>
				<Col xs={24} lg={15}>
					<Row>
						<Col xs={24}>
							<HandCoupling />
						</Col>
						<Col xs={24}>
							<Frequency />
						</Col>
					</Row>
				</Col>
			</Row>
			<Row justify="space-between" align="middle" style={{ marginTop: '1rem' }}>
				<Col xs={24} xl={8}>
					<Row>
						<Col xs={24} xl={12}>
							<StartHandHeight />
						</Col>
					</Row>
				</Col>
				<Col xs={24} xl={8}>
					<Row>
						<Col xs={24} xl={12}>
							<EndHandHeight />
						</Col>
					</Row>
				</Col>
				<Col xs={24} xl={6}>
					<Row>
						<Col xs={24} xl={16}>
							<ObjectWeight />
						</Col>
					</Row>
				</Col>
			</Row>
			<Row justify="start" align="middle">
				<Col xs={24} xl={9}>
					<Row>
						<Col xs={24} xl={11}>
							<StartHandDistance />
						</Col>
					</Row>
				</Col>
				<Col xs={24} xl={9}>
					<Row>
						<Col xs={24} xl={11}>
							<EndHandDistance />
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
