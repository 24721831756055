import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Table, Tag } from 'antd';

import { tableStyle, scoreStyle, totalScoreStyle, inputStyle, suffixStyle } from './styles';

const columnTypes = {
	PARENT: 'parent',
	CHILD: 'child',
	TOTAL: 'total'
};

const scoreThreshold = {
	individual: 3,
	total: 10
};

const renderInput = (input, row) => (
	<span style={inputStyle}>
		{input} <span style={suffixStyle}>{row.input_suffix}</span>
	</span>
);

const renderScore = (score, row) => {
	if (!score) {
		return null;
	}
	let color, style;
	if (row.type !== columnTypes.TOTAL) {
		color = parseFloat(score) >= scoreThreshold.individual ? '#B73E4C' : '#326B20';
		style = scoreStyle;
		return (
			<span
				style={{
					color: color,
					fontWeight: 'bold',
					fontSize: '16px'
				}}
			>
				{score}
			</span>
		);
	} else {
		color = parseFloat(score) >= scoreThreshold.total ? 'red' : 'green';
		style = totalScoreStyle;
		return (
			<Tag color={color} key={score} style={style}>
				{score}
			</Tag>
		);
	}
};

export const DetailsTable = ({ formattedReport }) => {
	const wristPostures = {
		FLEXION: I18n.get('Flexion'),
		EXTENSION: I18n.get('Extension')
	};

	const columns = [
		{
			title: I18n.get('Risk factor'),
			dataIndex: 'risk_factor',
			key: 'risk_factor',
			width: 250,
			render: (text, row) => {
				const Element = {
					parent: <h3 style={{ fontWeight: 'bold', fontSize: '14px' }}>{text}</h3>,
					child: <h4 style={{ marginLeft: '10px' }}>- {text}</h4>,
					total: <h3 style={{ fontWeight: 'bold' }}>{text}</h3>
				};

				return Element[row.type];
			}
		},
		{
			title: I18n.get('Left'),
			align: 'center',
			children: [
				{
					title: I18n.get('Input'),
					dataIndex: 'left_input',
					key: 'left_input',
					align: 'center',
					width: 130,
					render: renderInput
				},
				{
					title: I18n.get('Score'),
					dataIndex: 'left_score',
					key: 'left_score',
					align: 'center',
					width: 80,
					render: renderScore
				}
			]
		},
		{
			title: I18n.get('Right'),
			align: 'center',
			children: [
				{
					title: I18n.get('Input'),
					dataIndex: 'right_input',
					key: 'right_input',
					align: 'center',
					width: 130,
					render: renderInput
				},
				{
					title: I18n.get('Score'),
					dataIndex: 'right_score',
					key: 'right_score',
					align: 'center',
					width: 80,
					render: renderScore
				}
			]
		}
	];

	const data = [
		{
			risk_factor: I18n.get('Intensity of exertion'),
			type: columnTypes.PARENT,
			left_input: formattedReport.input_left_borg_scale,
			left_score: formattedReport.score_left_borg_scale,
			right_input: formattedReport.input_right_borg_scale,
			right_score: formattedReport.score_right_borg_scale,
			input_suffix: I18n.get('BS')
		},
		{
			risk_factor: I18n.get('Efforts per minute'),
			type: columnTypes.PARENT,
			left_input: formattedReport.effortsPerMinuteLeft,
			left_score: formattedReport.score_left_efforts_per_minute,
			right_input: formattedReport.effortsPerMinuteRight,
			right_score: formattedReport.score_right_efforts_per_minute,
			input_suffix: '/ min'
		},
		{
			risk_factor: I18n.get('Number of exertions'),
			type: columnTypes.CHILD,
			left_input: formattedReport.input_left_exertions,
			left_score: null,
			right_input: formattedReport.input_right_exertions,
			right_score: null,
			input_suffix: null
		},
		{
			risk_factor: I18n.get('Observation time'),
			type: columnTypes.CHILD,
			left_input: formattedReport.input_left_observation_time,
			left_score: null,
			right_input: formattedReport.input_right_observation_time,
			right_score: null,
			input_suffix: I18n.get('seconds')
		},
		{
			risk_factor: I18n.get('Duration per exertion'),
			type: columnTypes.PARENT,
			left_input: formattedReport.input_left_exertion_duration,
			left_score: formattedReport.score_left_exertion_duration,
			right_input: formattedReport.input_right_exertion_duration,
			right_score: formattedReport.score_right_exertion_duration,
			input_suffix: I18n.get('seconds')
		},
		{
			risk_factor: I18n.get('Hand/wrist posture'),
			type: columnTypes.PARENT,
			left_input: null,
			left_score: formattedReport.score_left_wrist_posture,
			right_input: null,
			right_score: formattedReport.score_right_wrist_posture,
			input_suffix: null
		},
		{
			risk_factor: I18n.get('Type of wrist posture'),
			type: columnTypes.CHILD,
			left_input: wristPostures[formattedReport.input_left_wrist_posture],
			left_score: null,
			right_input: wristPostures[formattedReport.input_right_wrist_posture],
			right_score: null,
			input_suffix: null
		},
		{
			risk_factor: I18n.get('Hand/wrist angle'),
			type: columnTypes.CHILD,
			left_input: formattedReport.input_left_wrist_angle,
			left_score: null,
			right_input: formattedReport.input_right_wrist_angle,
			right_score: null,
			input_suffix: '°'
		},
		{
			risk_factor: I18n.get('Duration of task per day'),
			type: columnTypes.PARENT,
			left_input: formattedReport.input_left_daily_duration,
			left_score: formattedReport.score_left_daily_duration,
			right_input: formattedReport.input_right_daily_duration,
			right_score: formattedReport.score_right_daily_duration,
			input_suffix: I18n.get('hours')
		},
		{
			risk_factor: I18n.get('Revised Strain Index (RSI)'),
			type: columnTypes.TOTAL,
			left_input: null,
			left_score: formattedReport.score_left_rsi,
			right_input: null,
			right_score: formattedReport.score_right_rsi,
			input_suffix: null
		}
	];

	return (
		<Table
			size="small"
			style={tableStyle}
			columns={columns}
			dataSource={data}
			pagination={false}
			rowKey="risk_factor"
		/>
	);
};
