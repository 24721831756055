import { Col } from 'antd';
import styled from 'styled-components';

interface CustomTaskContainerProps {
	selected: boolean;
}

export const CustomTaskContainer = styled(Col)<CustomTaskContainerProps>`
	height: 200px;
	display: flex;
	padding: 1rem 1rem;
	align-items: center;
	border-radius: 1.5rem;
	flex-direction: column;
	justify-content: center;
	transition: all 2s ease-in-out;
	color: ${({ selected }) => (selected ? '#2f54eb' : '#ffffff')};
	background-color: ${({ selected }) => (selected ? '#2f54eb' : '#ffffff')};

	@media (min-width: 1200px) {
		padding: 2rem 2rem;
	}

	@media (min-width: 1316px) {
		height: 225px;
		padding: 1rem 2.5rem;
	}

	:not(:nth-child(3), :nth-child(5)) {
		margin-right: 1rem;
	}

	.task {
		color: #000000;
		user-select: none;
		display: ${({ selected }) => (selected ? 'none' : 'flex')};
	}

	.task-hovered {
		color: #ffffff;
		user-select: none;
		display: ${({ selected }) => (selected ? 'flex' : 'none')};
	}

	transition: transform 0.3s, filter 0.15s ease-in;
	:hover {
		z-index: ${({ selected }) => (selected ? '1' : '10')};
		transform: ${({ selected }) => (selected ? 'scale(1)' : 'scale(1.2)')};
		filter: brightness(1.1);
		background-color: #2f54eb;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
		cursor: ${({ selected }) => (selected ? 'auto' : 'pointer')};
		.task {
			display: none;
		}

		.task-hovered {
			display: flex;
		}
	}
`;

export const CustomImageContainer = styled.div`
	height: 160px;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: end;
	flex-direction: column;

	.ant-image {
		max-width: 90px;
		margin-bottom: 1rem;

		@media (min-width: 1200px) {
			max-width: 120px;
		}
	}
`;

export const TaskTitle = styled.span`
	font-size: 0.8rem;
	font-weight: 600;
`;
