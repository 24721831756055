import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 30px;
`;

export const HousingContainer = styled.div``;

export const Housing = styled.div`
	position: relative;
	width: 20px;
	min-height: 150px;
	border-radius: 10px;
	border: 1px solid grey;
`;

export const EmptyLiquid = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	max-height: 140px;
	height: ${(props) => (1 - props.percentage) * 150}px;
	border-radius: 10px 10px 0px 0px;
	background-color: white;
`;

export const Liquid = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 150px;
	border-radius: 10px;
	background-image: linear-gradient(
		rgba(255, 40, 72, 0.6),
		rgba(255, 206, 86, 0.6),
		rgba(75, 192, 86, 0.6)
	);
`;

export const LabelsContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: left;
	min-width: 30px;
	min-height: 150px;
	margin-left: 8px;
`;

export const Label = styled.span`
	position: ${(props) => (props.percentage ? 'absolute' : 'relative')};
	top: ${(props) => (1 - props.percentage) * 150 - 10}px;

	margin-bottom: ${(props) => (props.isMin ? '-10' : '0')}px;
	margin-top: ${(props) => (props.isMax ? '-10' : '0')}px;
	color: ${(props) => (props.isMin || props.isMax ? 'grey' : 'black')};
	z-index: ${(props) => (!props.isMin && !props.isMax ? '10' : '0')};
	opacity: ${(props) => (props.hide ? '0' : '1')};
`;
