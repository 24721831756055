import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row } from 'antd';
import { Container } from './styles';

import { ConditionDetails } from './ConditionDetails';
import {
	ARM_POSTURES,
	BODY_POSTURES,
	TEMPORAL_DISTRIBUTIONS,
	WORK_CONDITIONS
} from '@/components/views/Report/KimManualHandling/enum.js';

export const Conditions = ({ report }) => {
	return (
		<Row justify="center" gutter={[20, 20]}>
			<Container sm={10}>
				<ConditionDetails
					title={I18n.get('Hand/arm position and movement')}
					currentValue={report.data.arm_posture}
					conditionLabels={ARM_POSTURES}
				/>
			</Container>
			<Container sm={10} offset={1}>
				<ConditionDetails
					title={I18n.get('Unfavourable working conditions')}
					currentValue={report.data.work_conditions}
					conditionLabels={WORK_CONDITIONS}
				/>
			</Container>
			<Container sm={10}>
				<ConditionDetails
					title={I18n.get('Work organization / temporal distribution')}
					currentValue={report.data.temporal_distribution}
					conditionLabels={TEMPORAL_DISTRIBUTIONS}
				/>
			</Container>
			<Container sm={10} offset={1}>
				<ConditionDetails
					title={I18n.get('Body posture/movement')}
					currentValue={report.data.body_posture}
					conditionLabels={BODY_POSTURES}
				/>
			</Container>
		</Row>
	);
};
