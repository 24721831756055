import React from 'react';
import { Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { LoadingOutlined } from '@ant-design/icons';

import { PieChart } from './PieChart';
import { PieLegend } from './PieLegend';

export const ContainerChart = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
`;

const chartIcon = <LoadingOutlined style={{ fontSize: 38 }} spin />;

const LoadingChart = () => (
	<ContainerChart>
		<Spin indicator={chartIcon} />
	</ContainerChart>
);

const DataChart = ({ data }) => (
	<Row gutter={[0, 20]}>
		{Object.keys(data).map((part, index) => {
			let payload = [];

			for (const score in data[part].total) {
				payload.push(data[part].total[score]);
			}

			return (
				<Col sm={8} style={{ height: 250 }} key={index}>
					<PieChart data={payload} label={I18n.get(part)} />
				</Col>
			);
		})}
		<Col sm={24} style={{ paddingTop: 45 }}>
			<PieLegend />
		</Col>
	</Row>
);

const ErrorChart = () => (
	<ContainerChart>
		<h2>{I18n.get('Unable to show results.')}</h2>
	</ContainerChart>
);

export const Charts = ({ loading = true, data, err }) => (
	<Col sm={24} style={{ paddingTop: '20px' }}>
		{data && <DataChart data={data} />}
		{err && <ErrorChart />}
		{loading && <LoadingChart />}
	</Col>
);
