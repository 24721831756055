import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import {
	Check,
	Close,
	Description,
	Exclamation,
	InnerContainer,
	Title,
	ErrorMessage
} from './styles';

export const DANGER_INDICATORS = {
	GREEN: <Check />,
	YELLOW: <Exclamation />,
	RED: <Close />
};

export function ConditionDetails({ title, currentValue, conditionLabels }) {
	if (!conditionLabels[currentValue]) {
		return (
			<InnerContainer>
				<Title level={4}>{title}</Title>
				<ErrorMessage>
					{I18n.get('Error, cannot get "') +
						currentValue +
						I18n.get('" from "') +
						I18n.get(title) +
						'"'}
				</ErrorMessage>
			</InnerContainer>
		);
	}

	return (
		<>
			<Title level={4}>{title}</Title>
			{typeof conditionLabels[currentValue].description === 'string' ? (
				<InnerContainer>
					<Col>{conditionLabels[currentValue].indicator}</Col>
					<Col sm={21} offset={1}>
						<Description>
							{I18n.get(conditionLabels[currentValue].description)}
						</Description>
					</Col>
				</InnerContainer>
			) : (
				conditionLabels[currentValue].description.map((item, index) => (
					<InnerContainer key={index}>
						<Col>{conditionLabels[currentValue].indicator}</Col>
						<Col sm={21} offset={1}>
							<Description>{I18n.get(item)}</Description>
						</Col>
					</InnerContainer>
				))
			)}
		</>
	);
}
