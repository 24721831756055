import styled from 'styled-components';
import { Col, Steps } from 'antd';

interface StepsProps {
	color: string;
}

export const Container = styled(Col)`
	padding: 3rem 0;
`;

export const CustomSteps = styled(Steps)<StepsProps>`
	.ant-steps-small {
		text-align: center;
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				margin: 0;
			}
		}
	}

	.ant-steps-icon {
		.step-hover {
			:hover {
				.step-checked-hovered {
					width: 135px;
					height: 32px;
					display: block;
					font-size: 1rem;
					cursor: pointer;
					background: none;
					margin-top: 25px;
					padding-top: 4px;
					margin-left: -5px;
					border-radius: 20px;
					border: solid 1px #2f54eb;
				}

				.step-number-hovered {
					width: 135px;
					height: 32px;
					display: block;
					font-size: 1rem;
					cursor: pointer;
					background: none;
					margin-top: 25px;
					padding-top: 4px;
					margin-left: -5px;
					border-radius: 20px;
					border: solid 1px #b4b5b8;
				}

				.disabled-step {
					cursor: not-allowed;
				}

				.step-checked {
					display: none;
					transition: 0.5s ease;
				}

				.step-number {
					display: none;
					transition: 0.5s ease;
				}
			}

			.step-checked-hovered {
				display: none;
			}

			.step-number-hovered {
				display: none;
			}

			.step-checked {
				width: 32px;
				height: 32px;
				display: block;
				color: #2f54eb;
				font-size: 1rem;
				cursor: pointer;
				padding-top: 3px;
				margin-top: 25px;
				margin-left: 45px;
				border-radius: 20px;
				border: solid 1px #2f54eb;

				@media (min-width: 2040px) {
					margin-left: 45px;
				}
			}

			.step-number {
				width: 32px;
				height: 32px;
				display: block;
				color: #b4b5b8;
				font-size: 1rem;
				cursor: pointer;
				padding-top: 3px;
				margin-top: 25px;
				margin-left: 45px;
				border-radius: 20px;
				border: solid 1px #b4b5b8;
			}
		}

		.step-name {
			color: #fff;
			height: 32px;
			width: 135px;
			display: block;
			font-size: 1rem;
			margin-top: 25px;
			padding-top: 4px;
			margin-left: -5px;
			border-radius: 20px;
			background: #2f54eb;

			@media (min-width: 2040px) {
				margin-left: 0px;
			}
		}
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-tail::after {
				background-color: #2f54eb;
				width: 1px;
			}
		}
	}

	.ant-steps-item-container {
		.ant-steps-item-tail {
			margin-top: 29px;
			margin-left: 50px;
			filter: contrast(0.5);

			@media (min-width: 2040px) {
				margin-left: 50px;
			}
		}
	}

	.ant-steps-item {
		.ant-steps-item-content {
			display: none;
			.ant-steps-item-title {
				display: none;
			}
		}
	}
`;
