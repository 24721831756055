import React, { useState, useEffect, useRef } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import Annotation from 'chartjs-plugin-annotation';
import { I18n } from '@aws-amplify/core';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { Col, Button } from 'antd';
import _ from 'lodash';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Legend,
	Tooltip,
	Filler
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	Annotation
);

const ENUM_BODY_PARTS = {
	neck: 'Neck',
	trunk: 'Trunk',
	left_upper_arm: 'Left shoulder',
	right_upper_arm: 'Right shoulder',
	left_lower_arm: 'Left elbow',
	right_lower_arm: 'Right elbow',
	left_knee: 'Left knee',
	right_knee: 'Right knee',
	left_ankle: 'Left ankle',
	right_ankle: 'Right ankle',
	left_hand: 'Left hand',
	right_hand: 'Right hand',
	hip: 'Hip'
};

const DownloadButton = styled(Button)`
	position: absolute;
	right: 30px;
	top: 75px;
`;

export function LineChart({ data, config, title }) {
	const chartRef = useRef(null);
	const [settings, setSettings] = useState([]);
	const [annotations, setAnnotations] = useState({});
	const [dataSet, setDataset] = useState({ labels: [], datasets: [] });

	let imageDownloadLink;

	const screenshot = () => {
		if (!imageDownloadLink) return;
		const a = document.createElement('a');
		a.href = imageDownloadLink;
		a.download = `${I18n.get(title)}.png`;
		a.click();
	};

	const orderByArray = (a, b) => (a.angle > b.angle ? -1 : a.angle < b.angle ? 1 : 0);

	useEffect(() => {
		if (data && data.length > 0) {
			setDataset((current) => ({
				...current,
				labels: [...data.map((item, index) => index)],
				datasets: [
					{
						label: I18n.get('Angle by time'),
						data: data,
						lineTension: 0.3,
						pointStyle: 'line',
						borderWidth: 3,
						pointRadius: 0,
						borderColor: '#3e95cd'
					}
				]
			}));
		}

		if (config && settings.length === 0) {
			setSettings(config.sort(orderByArray));
		}

		if (settings.length > 0 && _.isEmpty(annotations)) {
			settings.forEach((item, index) => {
				setAnnotations((current) => ({
					...current,
					[`line${index + 1}`]: {
						type: 'box',
						backgroundColor: item.color,
						borderWidth: 0,
						yMax: item.max,
						yMin: item.min,
						xMin: -0.5
					}
				}));
			});
		}
	}, [data, config, settings, annotations]);

	return (
		<Col style={{ marginInline: 80, height: '400px' }}>
			<DownloadButton
				shape="round"
				type="secondary"
				onClick={screenshot}
				icon={<DownloadOutlined style={{ fontSize: '1.2rem' }} />}
			/>
			<Line
				ref={chartRef}
				data={dataSet}
				plugins={Annotation}
				options={{
					maintainAspectRatio: false,
					responsive: true,
					scales: {
						x: {
							title: {
								display: true,
								text: I18n.get('Time in seconds (s)')
							}
						},
						y: {
							title: {
								display: true,
								text: I18n.get('Angle in degrees (°)')
							}
						}
					},
					plugins: {
						title: {
							display: true,
							text: I18n.get(ENUM_BODY_PARTS[title] || title),
							align: 'center',
							font: { weight: 'bold', size: 18 }
						},
						legend: {
							position: 'bottom',
							align: 'end',
							labels: {
								usePointStyle: true
							}
						},
						autocolors: false,
						datalabels: false,
						annotation: {
							drawTime: 'beforeDraw',
							annotations: annotations
						}
					},
					animation: {
						onComplete: function () {
							if (!chartRef.current) return;
							imageDownloadLink = chartRef.current?.canvas.toDataURL(
								'image/png',
								1
							);
						}
					}
				}}
			/>
		</Col>
	);
}
