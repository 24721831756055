import React from 'react';
import { I18n } from '@aws-amplify/core';
import { InputNumber } from 'antd';

import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import {
	DurationLabel,
	GridContainer,
	LeftDuration,
	LeftHeader,
	RightDuration,
	RightHeader,
	SuffixDuration,
	numberInputStyle
} from './styles';

export const DailyDuration = () => {
	return (
		<GridContainer span={24}>
			<LeftHeader>{I18n.get('Left')}</LeftHeader>
			<RightHeader>{I18n.get('Right')}</RightHeader>

			<DurationLabel>{I18n.get('Duration/day')}:</DurationLabel>
			<LeftDuration
				name="input_left_daily_duration"
				rules={[
					{
						required: true,
						message: false
					}
				]}
			>
				<InputNumber
					style={numberInputStyle}
					min={0}
					max={10}
					decimalSeparator={getDecimalSeparator()}
					step={0.01}
					placeholder={0.0}
				/>
			</LeftDuration>
			<RightDuration
				name="input_right_daily_duration"
				rules={[
					{
						required: true,
						message: false
					}
				]}
			>
				<InputNumber
					style={numberInputStyle}
					min={0}
					max={10}
					decimalSeparator={getDecimalSeparator()}
					step={0.01}
					placeholder={0.0}
				/>
			</RightDuration>
			<SuffixDuration>{I18n.get('hours')}</SuffixDuration>
		</GridContainer>
	);
};
