import React, { useState } from 'react';
import { Slider } from 'antd';

import { Container, CardsContainer, InfoCard, Description, headStyle } from './styles';
import { I18n } from '@aws-amplify/core';

const defaultCardStyle = {
	width: '100%'
};

export function CardSlider({ cards, cardStyle = defaultCardStyle, ...restProps }) {
	const formInitalSelectedIndex = Object.keys(cards).findIndex(
		(item) => item === restProps.value
	);

	const [selectedIndex, setSelectedIndex] = useState(formInitalSelectedIndex ?? 0);
	const cardQuantity = Object.keys(cards).length;

	function onChangeSelection(index) {
		setSelectedIndex(index);
		const selectedName = Object.keys(cards)[index];
		restProps.onChange(selectedName);
	}

	return (
		<Container>
			<Slider
				style={{
					boxSizing: 'border-box',
					width: `${100 - 100 / cardQuantity}%`,
					marginBottom: 20
				}}
				min={0}
				max={cardQuantity - 1}
				step={1}
				tooltipVisible={false}
				value={selectedIndex}
				onChange={onChangeSelection}
			/>
			<CardsContainer>
				{Object.keys(cards).map((card, index) => (
					<InfoCard
						key={index}
						selected={selectedIndex === index || restProps.value === card}
						onClick={() => onChangeSelection(index)}
						size="small"
						title={I18n.get(cards[card].title)}
						headStyle={headStyle}
						style={cardStyle}
					>
						<Description>{I18n.get(cards[card].description)}</Description>
					</InfoCard>
				))}
			</CardsContainer>
		</Container>
	);
}
