import React from 'react';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Divider } from 'antd';

import { InputSection } from '../InputSection';
import { Duration } from './Duration';
import { ForceFrequency } from './ForceFrequency';
import { ForceIntensity } from './ForceIntensity';
import { ForceTransfer } from './ForceTransfer';

const Title = styled.h2`
	text-align: left;
	font-weight: bold;
	margin-bottom: 30px;
	margin-left: 20px;
`;

export function TimeAndForce({ form }) {
	return (
		<Row align="middle" justify="center" style={{ marginBlock: 20 }}>
			<Col span={24}>
				<InputSection
					title={I18n.get('Total duration of this sub-activity per working day')}
					children={<Duration />}
					inline
				/>
			</Col>
			<Divider />
			<Col span={24} style={{ marginTop: 20 }}>
				<Title>{I18n.get('Left arm')}</Title>
				<InputSection
					title={I18n.get('Type of force exertion in the finger/hand area')}
					children={<ForceFrequency side="left" form={form} />}
					inline
				/>
			</Col>
			<Col span={24} style={{ marginBottom: 20 }}>
				<InputSection
					title={I18n.get(
						'Intensity of force exertion in the finger/hand area within a “standard minute”'
					)}
					children={<ForceIntensity side="left" />}
				/>
			</Col>
			<Divider />
			<Col span={24} style={{ marginTop: 20 }}>
				<Title>{I18n.get('Right arm')}</Title>
				<InputSection
					title={I18n.get('Type of force exertion in the finger/hand area')}
					children={<ForceFrequency side="right" form={form} />}
					inline
				/>
			</Col>
			<Col span={24} style={{ marginBottom: 20 }}>
				<InputSection
					title={I18n.get(
						'Intensity of force exertion in the finger/hand area within a “standard minute”'
					)}
					children={<ForceIntensity side="right" />}
				/>
			</Col>
			<Divider />
			<Col span={24}>
				<InputSection
					title={I18n.get('Force transfer / gripping conditions')}
					children={<ForceTransfer />}
				/>
			</Col>
		</Row>
	);
}
