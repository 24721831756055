import React from 'react';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const CriticalList = styled.ul`
	display: flex;
	justify-content: space-between;
`;

const CriticalItem = styled.li`
	flex: 1;
	margin-inline: 10px;
`;

export function Conclusion({ formattedReport }) {
	const criticalFactors = formattedReport.critical_factors;
	// converts decimal separator to locale setting
	criticalFactors.map((factor) => {
		if (typeof factor.value === 'string') return factor;
		factor.value = parseFloat(factor.value).toLocaleString();
		return factor;
	});

	const conclusionTexts = {
		VERY_HIGH: {
			text: [
				`The weight to be lifted`,
				` [${formattedReport.mass_m} kg] `,
				`is greater than the recommended weight limit (RWL)`,
				` [${formattedReport.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${formattedReport.lifting_index}] `,
				`is more than 3.`
			],
			conclusion:
				' There is a very high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.'
		},
		HIGH: {
			text: [
				`The weight to be lifted`,
				` [${formattedReport.mass_m} kg] `,
				`is greater than the recommended weight limit (RWL)`,
				` [${formattedReport.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${formattedReport.lifting_index}] `,
				`is more than 2 and less than 3.`
			],
			conclusion:
				' There is a high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.'
		},
		MODERATE: {
			text: [
				`The weight to be lifted`,
				` [${formattedReport.mass_m} kg] `,
				`is greater than the recommended weight limit (RWL)`,
				` [${formattedReport.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${formattedReport.lifting_index}] `,
				`is more than 1,5 and less than 2.`
			],
			conclusion:
				' There is a moderate risk. These values indicate that this job is somewhat stressful.'
		},
		LOW: {
			text: [
				`The weight to be lifted`,
				` [${formattedReport.mass_m} kg] `,
				`is greater than the recommended weight limit (RWL)`,
				` [${formattedReport.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${formattedReport.lifting_index}] `,
				`is more than 1 and less than 1,5.`
			],
			conclusion:
				' There is a low risk. These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.'
		},
		VERY_LOW: {
			text: [
				`The weight to be lifted`,
				` [${formattedReport.mass_m} kg] `,
				`is less than the recommended weight limit (RWL)`,
				` [${formattedReport.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${formattedReport.lifting_index}] `,
				`is less than 1.`
			],
			conclusion:
				' There is a very low risk. These values indicate that this job is adequate for the majority of industrial workers.'
		}
	};

	return (
		<Container>
			<p style={{ marginInline: '16px' }}>
				{conclusionTexts[formattedReport.risk].text.map((text) => I18n.get(text))}
				<strong>{I18n.get(conclusionTexts[formattedReport.risk].conclusion)}</strong>
			</p>
			{formattedReport.risk !== 'VERY_LOW' && (
				<>
					<p style={{ marginInline: '16px' }}>
						{I18n.get(
							'The most critical variables are listed below with suggestions:'
						)}
					</p>
					<CriticalList>
						{criticalFactors.map((factor) => (
							<CriticalItem key={factor.label}>
								<strong>
									{I18n.get(factor.title)}{' '}
									<span style={{ color: 'darkred' }}>[{factor.value}]</span>
								</strong>
								<p style={{ marginBottom: 0 }}>
									{I18n.get(factor.suggestion)}
								</p>
							</CriticalItem>
						))}
					</CriticalList>
				</>
			)}
		</Container>
	);
}
