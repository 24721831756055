import React from 'react'
import { Row, Col } from 'antd'
import { I18n } from '@aws-amplify/core'

export const PieLegend = () => (
	<Row gutter={[24, 10]} type="flex" justify="center">
		<Col sm={4} style={{ textAlign: 'center' }}>
			<div style={{ border: '1px solid rgba(75, 192, 86, 1)' }}>1</div>
			<div>{I18n.get('Acceptable')}</div>
		</Col>
		<Col sm={4} style={{ textAlign: 'center' }}>
			<div style={{ border: '1px solid rgba(255, 206, 86, 1)' }}>2</div>
			<div>{I18n.get('Investigate')}</div>
		</Col>
		<Col sm={4} style={{ textAlign: 'center' }}>
			<div style={{ border: '1px solid rgba(255, 40, 72, 1)' }}>3</div>
			<div>{I18n.get('Investigate and change soon')}</div>
		</Col>
		<Col sm={4} style={{ textAlign: 'center' }}>
			<div style={{ border: '1px solid rgba(153, 102, 255, 1)' }}>4</div>
			<div>{I18n.get('Investigate and change immediately')}</div>
		</Col>
	</Row>
)
