import React from 'react';
import { Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Container, Filler, Label } from './styles';

export const ScoreBar = ({ divisions, scores = [] }) => {
	const total = scores.reduce((total, cur) => cur + total, 0);
	return (
		<Container>
			{divisions.map((sector, index) => {
				const scorePercentage = scores[index] ? (scores[index] / total) * 100 : 0;
				return (
					<Tooltip
						key={index}
						title={`${I18n.get(sector.title)}: ${scorePercentage.toFixed(0)}%`}
					>
						<Filler bgColor={sector.color} percentage={scorePercentage}>
							{scorePercentage && scorePercentage > 9 ? (
								<Label>{scorePercentage.toFixed(0)}%</Label>
							) : null}
						</Filler>
					</Tooltip>
				);
			})}
		</Container>
	);
};
