import React from 'react';
import { Row, Col, Radio, Form, Typography, Slider, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SliderArrayType, SliderStyledType } from './types';

const TYPE = { DISTANCE: 'distance', DURATION: 'duration' };

export function DistanceOrTime() {
	const style: SliderStyledType = {
		textAlign: 'center',
		width: 'calc(18%)',
		fontSize: '12px'
	};

	const distance: SliderArrayType = [
		{
			label: I18n.get('≤ 40'),
			value: 0,
			style
		},
		{
			label: I18n.get('≤ 200'),
			value: 1,
			style
		},
		{
			label: I18n.get('≤ 400'),
			value: 2,
			style
		},
		{
			label: I18n.get('≤ 800'),
			value: 3,
			style
		},
		{
			label: I18n.get('≤ 1,200'),
			value: 4,
			style
		},
		{
			label: I18n.get('≤ 1,800'),
			value: 5,
			style
		},
		{
			label: I18n.get('≤ 2,500'),
			value: 6,
			style
		},
		{
			label: I18n.get('≤ 4,200'),
			value: 7,
			style
		},
		{
			label: I18n.get('≤ 6,300'),
			value: 8,
			style
		},
		{
			label: I18n.get('≤ 8,400'),
			value: 9,
			style
		},
		{
			label: I18n.get('≤ 11,000'),
			value: 10,
			style
		},
		{
			label: I18n.get('≤ 15,000'),
			value: 11,
			style
		},
		{
			label: I18n.get('≤ 20,000'),
			value: 12,
			style
		}
	];

	const duration: SliderArrayType = [
		{
			label: I18n.get('≤ 1'),
			value: 13,
			style
		},
		{
			label: I18n.get('≤ 5'),
			value: 14,
			style
		},
		{
			label: I18n.get('≤ 10'),
			value: 15,
			style
		},
		{
			label: I18n.get('≤ 20'),
			value: 16,
			style
		},
		{
			label: I18n.get('≤ 30'),
			value: 17,
			style
		},
		{
			label: I18n.get('≤ 45'),
			value: 18,
			style
		},
		{
			label: I18n.get('≤ 60'),
			value: 19,
			style
		},
		{
			label: I18n.get('≤ 100'),
			value: 20,
			style
		},
		{
			label: I18n.get('≤ 150'),
			value: 21,
			style
		},
		{
			label: I18n.get('≤ 210'),
			value: 22,
			style
		},
		{
			label: I18n.get('≤ 270'),
			value: 23,
			style
		},
		{
			label: I18n.get('≤ 360'),
			value: 24,
			style
		},
		{
			label: I18n.get('≤ 480'),
			value: 25,
			style
		}
	];

	for (let i = 0; i < 13; i++) {
		const emptyValue = { label: I18n.get(' '), value: 0, style };
		duration.unshift(emptyValue);
	}

	const marks: any = {
		distance,
		duration
	};

	return (
		<Row gutter={[10, 10]} align="middle" justify="center" style={{ marginBlock: 20 }}>
			<Col span={18}>
				<Typography.Title level={5}>{I18n.get('Distance or duration')}</Typography.Title>
			</Col>
			<Col span={18} style={{ marginTop: '20px' }}>
				<Form.Item
					name="option_direction"
					rules={[
						{
							required: true,
							message: I18n.get('Choose an option')
						}
					]}
				>
					<Radio.Group buttonStyle="solid">
						<Radio.Button value="distance">{I18n.get('By distance')}</Radio.Button>
						<Radio.Button value="duration">{I18n.get('By duration')}</Radio.Button>
					</Radio.Group>
				</Form.Item>
			</Col>
			<Col span={18}>
				<Divider />
			</Col>
			<Col span={18}>
				<Form.Item noStyle shouldUpdate={(prev, next) => prev.option_direction !== next.option_direction}>
					{({ getFieldValue }) => <TitleOption option={getFieldValue('option_direction')} />}
				</Form.Item>
			</Col>
			<Col span={18} style={{ marginTop: '20px' }}>
				<Form.Item noStyle shouldUpdate={(prev, next) => prev.option_direction !== next.option_direction}>
					{({ getFieldValue }) =>
						getFieldValue('option_direction') ? (
							<Form.Item
								rules={[
									{
										required: true,
										message: I18n.get('Choose an option')
									}
								]}
								name="distance_or_duration_input"
							>
								{getFieldValue('option_direction') === TYPE.DISTANCE ? (
									<Slider max={12} step={1} marks={marks[getFieldValue('option_direction')]} />
								) : (
									<Slider
										min={13}
										max={25}
										step={1}
										marks={marks[getFieldValue('option_direction')]}
									/>
								)}
							</Form.Item>
						) : null
					}
				</Form.Item>
			</Col>
		</Row>
	);
}

const TitleOption: React.FC<{ option: string }> = ({ option }) => (
	<Typography.Title level={5}>
		{option === TYPE.DISTANCE && I18n.get('Distance per work day (in meters)')}
		{option === TYPE.DURATION ? I18n.get('Duration per work day (in minutes)') : ''}
	</Typography.Title>
);
