import styled from 'styled-components';
import { Col, Form } from 'antd';

export const LeftHeader = styled.span`
	grid-area: left-header;
`;

export const RightHeader = styled.span`
	grid-area: right-header;
`;

export const DurationLabel = styled.p`
	grid-area: duration-label;
	text-align: right;
	margin: 0;
`;

export const LeftDuration = styled(Form.Item)`
	grid-area: left-duration;
`;

export const RightDuration = styled(Form.Item)`
	grid-area: right-duration;
`;

export const SuffixDuration = styled.span`
	grid-area: suffix-duration;
`;

export const GridContainer = styled(Col)`
	display: grid;
	justify-content: center;
	align-items: center;
	margin-left: 80px;
	text-align: center;
	gap: 10px 20px;
	grid-template-columns: auto auto auto;
	grid-template-areas:
		'. left-header right-header .'
		'duration-label left-duration right-duration suffix-duration';

	${LeftDuration}, ${RightDuration} {
		margin: 0px;
	}

	${SuffixDuration} {
		margin-left: -5px;
		color: grey;
	}
`;

export const numberInputStyle = {
	width: '90px'
};
