import React from 'react';

import { ClassificationGauge } from '@/components/ui/Reports/ClassificationGauge';
import { Container, GaugeContainer, ResultLabel, ResultText } from './styles';
import { I18n } from '@aws-amplify/core';

const risk = {
	LOW: {
		label: 'Low',
		gauge: 'low',
		color: 'green',
		type: 'success'
	},
	SLIGHTLY_INCREASED: {
		label: 'Slightly increased',
		gauge: 'medium',
		color: 'orange',
		type: 'warning'
	},
	SUBSTANTIALLY_INCREASED: {
		label: 'Substantially increased',
		gauge: 'medium',
		color: 'orange',
		type: 'warning'
	},
	HIGH: {
		label: 'High',
		gauge: 'high',
		color: 'red',
		type: 'danger'
	}
};

export function Gauge({ report }) {
	return (
		<Container>
			<ResultText strong type={risk[report.data.risk_load].type}>
				{I18n.get(risk[report.data.risk_load].label)}
			</ResultText>
			<GaugeContainer>
				<ClassificationGauge classification={risk[report.data.risk_load].gauge} />
			</GaugeContainer>
			<ResultLabel color={risk[report.data.risk_load].color}>
				{report.data.risk_score}
			</ResultLabel>
		</Container>
	);
}
