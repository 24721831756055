import moment from 'moment';

const DECIMAL = 'decimal';

export function getDecimalSeparator(): string | undefined {
	const locale = moment.locale();
	const defaultNumber = 1.1;

	const numberParts: { type: string; value: string }[] = new Intl.NumberFormat(locale).formatToParts(defaultNumber);

	const getType = numberParts.find((part) => part.type === DECIMAL);

	return getType?.value;
}
