import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Radio } from 'antd';
import { Container, FrequencyInput, Suffix } from './styles';

export const ForceFrequency = ({ form, side }) => {
	const [forceType, setForceType] = useState(form.getFieldValue(side + '_force_type') ?? null);

	return (
		<>
			<Container>
				<Form.Item
					name={side + '_force_type'}
					rules={[
						{
							required: true,
							message: I18n.get('Invalid')
						}
					]}
					style={{ margin: 0 }}
				>
					<Radio.Group buttonStyle="solid" onChange={(event) => setForceType(event.target.value)}>
						<Radio.Button value="HOLDING">{I18n.get('Holding')}</Radio.Button>
						<Radio.Button value="MOVING">{I18n.get('Moving')}</Radio.Button>
					</Radio.Group>
				</Form.Item>
			</Container>
			<Container>
				<Form.Item
					name={side + '_force_frequency'}
					rules={[
						{
							required: true,
							message: I18n.get('Invalid')
						}
					]}
					style={{ margin: 0 }}
				>
					<FrequencyInput min={0} max={forceType === 'HOLDING' ? 60 : 90} step={5} />
				</Form.Item>
				<Suffix>
					{!forceType
						? ''
						: forceType === 'HOLDING'
						? I18n.get('average (seconds holding / minute)')
						: I18n.get('average (movements / minute)')}
				</Suffix>
			</Container>
		</>
	);
};
