import React from 'react';
import { Form } from 'antd';

import { CardSlider } from '@/components/ui/Sliders/CardSlider';
import { Container } from './styles';

import { FORCE_INTENSITIES } from '../../enum';

export const ForceIntensity = ({ side }) => {
	return (
		<Container>
			<Form.Item
				name={side + '_force_intensity'}
				rules={[
					{
						required: true,
						message: 'Invalid'
					}
				]}
				style={{ margin: 0 }}
			>
				<CardSlider cards={FORCE_INTENSITIES} />
			</Form.Item>
		</Container>
	);
};
