import React, { useState, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Divider, Typography, Image, Slider, Tooltip } from 'antd';
import { WrapperCard, ButtonCard } from './styles';

import wheelOne from '@/assets/img/wheel1.svg';
import wheelTwo from '@/assets/img/wheel2.svg';
import wheelThree from '@/assets/img/wheel3.svg';
import wheelFour from '@/assets/img/wheel4.svg';
import wheelFive from '@/assets/img/wheel5.svg';
import wheelSix from '@/assets/img/wheel6.svg';
import wheelSeven from '@/assets/img/wheel7.svg';
import wheelEight from '@/assets/img/wheel8.svg';

import barrowOne from '@/assets/img/barrow1.png';
import barrowTwo from '@/assets/img/barrow2.png';
import barrowThree from '@/assets/img/barrow3.png';
import conveyor from '@/assets/img/conveyor.png';
import crane from '@/assets/img/crane.png';
import carriageOne from '@/assets/img/carriage1.png';
import carriageTwo from '@/assets/img/carriage2.png';
import carriageThree from '@/assets/img/carriage3.png';
import carriageFour from '@/assets/img/carriage4.png';
import carriageFive from '@/assets/img/carriage5.png';
import carriageSix from '@/assets/img/carriage6.png';

const style = {
	textAlign: 'center',
	width: 'calc(18%)',
	fontSize: '12px'
};

export function Mass() {
	const form = Form.useFormInstance();

	const [vehicleType, setVehicleType] = useState(
		form.getFieldValue('vehicle_input') >= 0 && form.getFieldValue('vehicle_input') < 8
			? form.getFieldValue('vehicle_input')
			: null
	);

	const [activeVehicle, setActiveVehicle] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false
	]);
	const [activeDriveway, setActiveDriveway] = useState([false, false, false, false, false]);
	const [activeInclination, setActiveInclination] = useState([false, false, false, false]);

	function selectvehicle(vehicle) {
		if (vehicle.isButton) {
			setVehicleType(vehicle.id);

			if (vehicle.active !== 'active') {
				setActiveVehicle((prev) => ({
					...prev,
					0: false,
					1: false,
					2: false,
					3: false,
					4: false,
					5: false,
					6: false,
					7: false,
					8: false,
					9: false,
					[vehicle.id]: true
				}));
				form.setFieldsValue({
					vehicle_input: vehicle.id
				});
			}
			if (vehicle.active === 'active') {
				setActiveVehicle((prev) => ({
					...prev,
					0: false,
					1: false,
					2: false,
					3: false,
					4: false,
					5: false,
					6: false,
					7: false,
					8: false,
					9: false
				}));
				form.setFieldsValue({
					vehicle_input: null
				});
			}
		}
	}

	function selectDriveway(driveway) {
		if (driveway.active !== 'active') {
			setActiveDriveway((prev) => ({
				...prev,
				0: false,
				1: false,
				2: false,
				3: false,
				4: false,
				[driveway.id]: true
			}));
			form.setFieldsValue({
				driveway_conditions_input: driveway.id
			});
		}
		if (driveway.active === 'active') {
			setActiveDriveway((prev) => ({
				...prev,
				0: false,
				1: false,
				2: false,
				3: false,
				4: false
			}));
			form.setFieldsValue({
				driveway_conditions_input: null
			});
		}
	}

	function selectInclination(inclination) {
		if (inclination.active !== 'active') {
			setActiveInclination((prev) => ({
				...prev,
				0: false,
				1: false,
				2: false,
				3: false,
				[inclination.id]: true
			}));
			form.setFieldsValue({
				inclination_or_stairs_input: inclination.id
			});
		}
		if (inclination.active === 'active') {
			setActiveInclination((prev) => ({
				...prev,
				0: false,
				1: false,
				2: false,
				3: false
			}));
			form.setFieldsValue({ inclination_or_stairs_input: null });
		}
	}

	const chooseOptionsCarriages = [
		{
			id: 3,
			title: '3',
			icon: wheelOne,
			secondIcon: wheelTwo,
			style: {
				height: '60px',
				marginRight: '30px'
			},
			column: 8,
			isButton: true,
			active: activeVehicle[3] ? 'active' : ''
		},
		{
			id: 4,
			title: '4',
			icon: wheelOne,
			secondIcon: wheelTwo,
			style: {
				height: '60px',
				marginRight: '30px'
			},
			column: 8,
			isButton: true,
			active: activeVehicle[4] ? 'active' : ''
		},
		{
			id: 7,
			title: '7',
			icon: wheelEight,
			style: {
				height: '60px'
			},
			column: 8,
			isButton: true,
			active: activeVehicle[7] ? 'active' : ''
		},
		{
			id: 10,
			title: '10',
			icon: carriageOne,
			style: {
				height: '120px'
			},
			column: 8,
			isButton: false,
			tooltip: I18n.get('Reference image: only swivel castors')
		},
		{
			id: 13,
			title: '10',
			icon: carriageTwo,
			style: {
				height: '120px'
			},
			column: 8,
			isButton: false,
			tooltip: I18n.get('Reference image: only swivel castors')
		},
		{
			id: 12,
			title: '12',
			icon: carriageSix,
			style: {
				height: '120px'
			},
			column: 8,
			isButton: false,
			tooltip: I18n.get('Reference image: pedestrian-controlled')
		},
		{
			id: 5,
			title: '5',
			icon: wheelThree,
			secondIcon: wheelFour,
			style: {
				height: '60px',
				marginRight: '30px'
			},
			column: 12,
			isButton: true,
			active: activeVehicle[5] ? 'active' : '',
			marginTop: { marginTop: '20px' }
		},
		{
			id: 6,
			title: '6',
			icon: wheelFive,
			secondIcon: wheelSix,
			thirdIcon: wheelSeven,
			style: {
				height: '60px',
				marginRight: '30px'
			},
			column: 12,
			isButton: true,
			active: activeVehicle[6] ? 'active' : '',
			marginTop: { marginTop: '20px' }
		},
		{
			id: 11,
			title: '11',
			icon: carriageThree,
			secondIcon: carriageFour,
			thirdIcon: carriageFive,
			style: {
				height: '120px'
			},
			column: 24,
			isButton: false,
			tooltip: I18n.get('Reference image: with fixed castors or lockable swivel castors')
		}
	];

	const chooseOptionsBarrows = [
		{
			id: 0,
			title: '0',
			icon: barrowOne,
			style: {
				height: '120px'
			},
			column: 8,
			isButton: true,
			active: activeVehicle[0] ? 'active' : ''
		},
		{
			id: 1,
			title: '1',
			icon: barrowTwo,
			style: {
				height: '120px'
			},
			column: 8,
			isButton: true,
			active: activeVehicle[1] ? 'active' : ''
		},
		{
			id: 2,
			title: '2',
			icon: barrowThree,
			style: {
				height: '120px'
			},
			column: 8,
			isButton: true,
			active: activeVehicle[2] ? 'active' : ''
		}
	];

	const chooseOptionsConveyorsCranes = [
		{
			id: 8,
			title: '8',
			icon: conveyor,
			style: {
				height: '120px'
			},
			column: 8,
			isButton: true,
			active: activeVehicle[8] ? 'active' : ''
		},
		{
			id: 9,
			title: '9',
			icon: crane,
			style: {
				height: '120px'
			},
			column: 8,
			isButton: true,
			active: activeVehicle[9] ? 'active' : ''
		}
	];

	const drivewayOptions = [
		{
			id: 0,
			value: 0,
			label: I18n.get('Driveway completely level, smooth, solid, dry, without inclinations'),
			style: {
				fontWeight: 'bold'
			},
			active: activeDriveway[0] ? 'active' : ''
		},
		{
			id: 1,
			value: 1,
			label: I18n.get('Driveway mostly smooth and level, with small damaged spots/faults, without inclinations'),
			style: {
				fontWeight: 'bold'
			},
			active: activeDriveway[1] ? 'active' : ''
		},
		{
			id: 2,
			value: 2,
			label: I18n.get('Mixture of cobbles, concrete, asphalt, slight inclinations, dropped kerb'),
			style: {
				fontWeight: 'bold'
			},
			active: activeDriveway[2] ? 'active' : ''
		},
		{
			id: 3,
			value: 3,
			label: I18n.get('Mixture of roughly cobbled, hard sand, slight inclinations, small edges/sills'),
			style: {
				fontWeight: 'bold'
			},
			active: activeDriveway[3] ? 'active' : ''
		},
		{
			id: 4,
			value: 4,
			label: I18n.get(
				'Earth or roughly cobbled driveway, potholes, heavy soiling, slight inclinations, landings, sills'
			),
			style: {
				fontWeight: 'bold'
			},
			active: activeDriveway[4] ? 'active' : ''
		}
	];

	const inclinationOptions = [
		{
			id: 0,
			value: 0,
			label: I18n.get('Inclinations of 2 ≤ 4° (4 ≤ 8%)'),
			style: {
				fontWeight: 'bold'
			},
			active: activeInclination[0] ? 'active' : ''
		},
		{
			id: 1,
			value: 1,
			label: I18n.get('Inclinations of 5 ≤ 10° (9 ≤ 18%)'),
			style: {
				fontWeight: 'bold'
			},
			active: activeInclination[1] ? 'active' : ''
		},
		{
			id: 2,
			value: 2,
			label: I18n.get('Stairs, inclinations > 10° (18%)'),
			style: {
				fontWeight: 'bold'
			},
			active: activeInclination[2] ? 'active' : ''
		},
		{
			id: 3,
			value: 3,
			label: I18n.get('No significant inclination'),
			style: {
				fontWeight: 'bold'
			},
			active: activeInclination[3] ? 'active' : ''
		}
	];

	const massOptions = [
		{
			label: I18n.get('≤ 50'),
			value: 0,
			style
		},
		{
			label: I18n.get('≤ 100'),
			value: 1,
			style
		},
		{
			label: I18n.get('≤ 200'),
			value: 2,
			style
		},
		{
			label: I18n.get('≤ 300'),
			value: 3,
			style
		},
		{
			label: I18n.get('≤ 400'),
			value: 4,
			style
		},
		{
			label: I18n.get('≤ 600'),
			value: 5,
			style
		},
		{
			label: I18n.get('≤ 800'),
			value: 6,
			style
		},
		{
			label: I18n.get('≤ 1,000'),
			value: 7,
			style
		},
		{
			label: I18n.get('≤ 1,300'),
			value: 8,
			style
		},
		{
			label: I18n.get('> 1,300'),
			value: 9,
			style
		}
	];

	useEffect(() => {
		if (form.getFieldValue('vehicle_input') >= 0) {
			setActiveVehicle((prev) => ({
				...prev,
				[form.getFieldValue('vehicle_input')]: true
			}));
		}
		if (form.getFieldValue('driveway_conditions_input') >= 0) {
			setActiveDriveway((prev) => ({
				...prev,
				[form.getFieldValue('driveway_conditions_input')]: true
			}));
		}

		if (form.getFieldValue('inclination_or_stairs_input') >= 0) {
			setActiveInclination((prev) => ({
				...prev,
				[form.getFieldValue('inclination_or_stairs_input')]: true
			}));
		}
	}, [form]);

	return (
		<Row gutter={[10, 30]}>
			<Col sm={24} style={{ paddingTop: '35px' }}>
				<Typography.Title level={5}>{I18n.get('Select one of the vehicles or a wheel type:')}</Typography.Title>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Row
					gutter={[10, 0]}
					style={{
						marginTop: '15px',
						marginBottom: '15px',
						marginRight: '10px',
						marginLeft: '10px'
					}}
				>
					<Col sm={24}>
						<Form.Item
							name="vehicle_input"
							rules={[
								{
									required: true,
									message: I18n.get('Choose an option')
								}
							]}
						>
							<div>
								<Row>
									<Col sm={24}>
										<Typography.Title level={5}>{I18n.get('Barrows')}</Typography.Title>
									</Col>
								</Row>
								<Row gutter={[10, 10]}>
									{chooseOptionsBarrows.map((option, index) => (
										<Col sm={option.column} offset={option.offset} key={index}>
											<WrapperCard
												onClick={() => selectvehicle(option)}
												isButton={option.isButton}
												height="auto"
												className={option.active}
											>
												<Row className="card_body">
													<Col sm={24} style={{ textAlign: 'center' }}>
														<Col sm={24}>
															<Image
																preview={false}
																src={option.icon}
																style={option.style}
															/>
															{option?.secondIcon && (
																<Image
																	preview={false}
																	src={option.secondIcon}
																	style={option.style}
																/>
															)}
															{option?.thirdIcon && (
																<Image
																	preview={false}
																	src={option.thirdIcon}
																	style={option.style}
																/>
															)}
														</Col>
													</Col>
												</Row>
											</WrapperCard>
										</Col>
									))}
								</Row>
								<br />
								<Typography.Title level={5}>{I18n.get('Carriages')}</Typography.Title>
								<Row gutter={[10, 10]}>
									{chooseOptionsCarriages.map((option, index) => (
										<Col sm={option.column} offset={option.offset} key={index}>
											<Tooltip title={option.tooltip}>
												<WrapperCard
													onClick={() => selectvehicle(option)}
													isButton={option.isButton}
													height="auto"
													className={option.active}
													style={option.marginTop}
												>
													<Row className="card_body">
														<Col sm={24} style={{ textAlign: 'center' }}>
															<Col sm={24}>
																<Image
																	preview={false}
																	src={option.icon}
																	style={option.style}
																/>
																{option?.secondIcon && (
																	<Image
																		preview={false}
																		src={option.secondIcon}
																		style={option.style}
																	/>
																)}
																{option?.thirdIcon && (
																	<Image
																		preview={false}
																		src={option.thirdIcon}
																		style={option.style}
																	/>
																)}
															</Col>
														</Col>
													</Row>
												</WrapperCard>
											</Tooltip>
										</Col>
									))}
								</Row>
								<br />
								<Row>
									<Col sm={8}>
										<Typography.Title level={5}>{I18n.get('Conveyors')}</Typography.Title>
									</Col>
									<Col sm={8}>
										<Typography.Title level={5}>{I18n.get('Cranes')}</Typography.Title>
									</Col>
								</Row>
								<Row gutter={[10, 10]}>
									{chooseOptionsConveyorsCranes.map((option, index) => (
										<Col sm={option.column} offset={option.offset} key={index}>
											<WrapperCard
												onClick={() => selectvehicle(option)}
												isButton={option.isButton}
												height="auto"
												className={option.active}
											>
												<Row className="card_body">
													<Col sm={24} style={{ textAlign: 'center' }}>
														<Col sm={24}>
															<Image
																preview={false}
																src={option.icon}
																style={option.style}
															/>
															{option?.secondIcon && (
																<Image
																	preview={false}
																	src={option.secondIcon}
																	style={option.style}
																/>
															)}
															{option?.thirdIcon && (
																<Image
																	preview={false}
																	src={option.thirdIcon}
																	style={option.style}
																/>
															)}
														</Col>
													</Col>
												</Row>
											</WrapperCard>
										</Col>
									))}
								</Row>
							</div>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Divider />
					</Col>
					{vehicleType < 8 && vehicleType != null ? (
						<Col sm={14}>
							<Col sm={24}>
								<Typography.Title level={5}>{I18n.get('Driveway conditions:')}</Typography.Title>
							</Col>
							<Col
								sm={24}
								style={{
									textAlign: 'center',
									padding: '35px 0px 0px 0px'
								}}
							>
								<Form.Item
									name="driveway_conditions_input"
									rules={[
										{
											required: true,
											message: I18n.get('Choose an option')
										}
									]}
								>
									<>
										{drivewayOptions.map((option, index) => (
											<Col sm={option.column} offset={option.offset} key={index}>
												<ButtonCard
													onClick={() => selectDriveway(option)}
													isButton={true}
													className={option.active}
												>
													<span
														style={{
															whiteSpace: 'initial'
														}}
													>
														{option.label}
													</span>
												</ButtonCard>
											</Col>
										))}
									</>
								</Form.Item>
							</Col>
						</Col>
					) : (
						''
					)}
					<Col sm={9} offset={vehicleType < 8 && vehicleType != null ? 1 : 0}>
						<Col sm={24}>
							<Typography.Title level={5}>{I18n.get('Inclination or stairs:')}</Typography.Title>
						</Col>
						<Col
							sm={24}
							style={{
								textAlign: 'center',
								padding: '35px 0px 35px 0px'
							}}
						>
							<Form.Item
								name="inclination_or_stairs_input"
								rules={[
									{
										required: true,
										message: I18n.get('Choose an option')
									}
								]}
							>
								<>
									{inclinationOptions.map((option, index) => (
										<Col sm={option.column} offset={option.offset} key={index}>
											<ButtonCard
												onClick={() => selectInclination(option)}
												isButton={true}
												className={option.active}
											>
												<span
													style={{
														whiteSpace: 'initial'
													}}
												>
													{option.label}
												</span>
											</ButtonCard>
										</Col>
									))}
								</>
							</Form.Item>
						</Col>
					</Col>
					<Col sm={24} style={{ marginTop: '-40px' }}>
						<Divider />
					</Col>
					<Col sm={24}>
						<Typography.Title level={5}>
							{I18n.get('Select the mass to be transported (in kg):')}
						</Typography.Title>
					</Col>
					<Col sm={24} style={{ textAlign: 'center', padding: '35px 0px 0px 0px' }}>
						<Form.Item
							name="mass_input"
							rules={[
								{
									required: true,
									message: I18n.get('Choose an option')
								}
							]}
						>
							<Slider max={9} step={1} optionType="button" buttonStyle="solid" marks={massOptions} />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
