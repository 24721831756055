import { useEffect, useState } from 'react';
import Api from '@/services/api';

export function useFetch({ url, method = 'get', body = {}, options }) {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [isFetching, setIsFetching] = useState(true);

	useEffect(() => {
		Api({ url, method, data: body, ...options })
			.then(response => {
				setData(response.data);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, []);

	function doFetch() {
		Api({ url: url, method: method, data: body, ...options })
			.then(response => setData(response.data))
			.catch(err => setError(err))
			.finally(() => setIsFetching(false));
	}

	return { data, error, isFetching, doFetch };
}
