export const MIN_ACCEPTABLE_RISK_RPN = 1;
export const MIN_MODERATE_RISK_RPN = 8;
export const MIN_HIGH_RISK_RPN = 36;
export const MIN_VERY_HIGH_RISK_RPN = 125;
export const MIN_SERIOUS_RISK_RPN = 180;

export const MAX_ACCEPTABLE_RISK_RPN = 7;
export const MAX_MODERATE_RISK_RPN = 35;
export const MAX_HIGH_RISK_RPN = 124;
export const MAX_VERY_HIGH_RISK_RPN = 179;
export const MAX_SERIOUS_RISK_RPN = 216;

export const DEFAULT_RISK_RPN_COLOR = '#2F54EB';
export const ACCEPTABLE_RISK_RPN_COLOR = '#2CC852';
export const MODERATE_RISK_RPN_COLOR = '#FFDE31';
export const HIGH_RISK_RPN_COLOR = '#F78A38';
export const VERY_HIGH_RISK_RPN_COLOR = '#E74150';
export const SERIOUS_RISK_RPN_COLOR = '#9B54E2';
