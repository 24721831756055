import React from 'react';
import { Form } from 'antd';

import { RadioCards } from '@/components/ui/Inputs/RadioCards';
import { Container, Image, ImagePairContainer, ImagesContainer } from './styles';

import { ARM_POSTURES } from '../../enum';

export const ArmPosture = () => {
	return (
		<Container>
			<ImagesContainer>
				{Object.keys(ARM_POSTURES).map((item, index) => (
					<ImagePairContainer key={index}>
						<Image src={ARM_POSTURES[item].image1} />
						<Image src={ARM_POSTURES[item].image2} />
					</ImagePairContainer>
				))}
			</ImagesContainer>
			<Form.Item
				name="arm_posture"
				rules={[
					{
						required: true,
						message: 'Invalid'
					}
				]}
				style={{ margin: 0 }}
				valuePropName="checked"
			>
				<RadioCards
					name="arm_posture"
					cardType="vertical"
					direction="row"
					cards={ARM_POSTURES}
					cardStyle={{ width: 230, height: 230 }}
				/>
			</Form.Item>
		</Container>
	);
};
