import Api from '@/services/api';
import { BaseContextType, Response } from '@/types';

async function saveComments(payload: BaseContextType) {
	const { organizationId, companyId, fileId } = payload;
	const body = {
		organization_id: organizationId,
		company_id: companyId,
		file_id: fileId,
		data: payload.data
	};
	const { data } = await Api.put<Response>('/ergonomic-tool/preliminary-analysis/save-comments', body);
	return data;
}

export const Services = { saveComments };
