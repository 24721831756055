import React from 'react';
import { Button, Result } from 'antd';
import { I18n } from '@aws-amplify/core';

export const ErrorMessage = ({ onRetry }) => (
	<Result
		status="error"
		title={I18n.get('Submission Failed')}
		subTitle={I18n.get(
			'Please check and modify the following information before resubmitting.'
		)}
		extra={[
			<Button type="primary" key="retry" onClick={onRetry}>
				Retry
			</Button>
		]}
	/>
);
