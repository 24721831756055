import styled from 'styled-components';

export const TabsContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const tabStyle = { height: '50%', marginTop: 20 };

const tabBtnStyle = {
	height: '50%',
	position: 'absolute',
	width: 50,
	border: 0,
	top: 130,
	background: 'none'
};

export const nextBtnStyle = { ...tabBtnStyle, right: 10 };

export const prevBtnStyle = { ...tabBtnStyle, left: 10 };

export const iconStyle = { fontSize: '20px' };

export const Footnote = styled.p`
	font-size: 0.8rem;
	color: grey;
	margin-left: 10px;
`;
