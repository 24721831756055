import styled from 'styled-components';
import { Card } from 'antd';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const CardsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	gap: 20px;

	@media (max-width: 1600px) {
		gap: 5px;
	}
`;

export const InfoCard = styled(Card)`
	cursor: pointer;
	background: none;
	border-radius: 2px;
	border: ${(props) => (props.selected ? '2px solid #1890FF' : '2px solid #e6e8e8')};
	transition: border ease 0.2s;

	min-height: 100%;
`;

export const Description = styled.p`
	text-align: center;
`;

export const headStyle = {
	fontWeight: 'bold',
	textAlign: 'center'
};
