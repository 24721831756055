import React from 'react';
import { Form, InputNumber } from 'antd';
import { I18n } from '@aws-amplify/core';

import extensionImg from '@/assets/img/ergonomic_tools/hand-extension.png';
import flexionImg from '@/assets/img/ergonomic_tools/hand-flexion.png';
import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import {
	ButtonCustom,
	Container,
	HandImage,
	ImageContainer,
	numberInputStyle,
	RadioCustom,
	SideContainer
} from './styles';

const ButtonImage = ({ image }) => {
	return (
		<ImageContainer>
			<HandImage src={image} alt="" />
		</ImageContainer>
	);
};

export const HandPosture = () => {
	return (
		<Container>
			<SideContainer>
				<span>{I18n.get('Left')}</span>
				<Form.Item
					name="input_left_wrist_posture"
					rules={[
						{
							required: true,
							message: I18n.get('Enter the required values')
						}
					]}
				>
					<RadioCustom>
						<ButtonCustom value="EXTENSION">
							<ButtonImage image={flexionImg} />
						</ButtonCustom>
						<ButtonCustom value="FLEXION">
							<ButtonImage image={extensionImg} />
						</ButtonCustom>
					</RadioCustom>
				</Form.Item>
				<Form.Item
					label={I18n.get('Degrees')}
					name="input_left_wrist_angle"
					rules={[
						{
							required: true,
							message: false
						}
					]}
				>
					<InputNumber
						style={numberInputStyle}
						min={0}
						max={90}
						decimalSeparator={getDecimalSeparator()}
						step={0.01}
						placeholder={0.0}
					/>
				</Form.Item>
			</SideContainer>

			<SideContainer>
				<span>{I18n.get('Right')}</span>
				<Form.Item
					name="input_right_wrist_posture"
					rules={[
						{
							required: true,
							message: I18n.get('Enter the required values')
						}
					]}
				>
					<RadioCustom>
						<ButtonCustom value="EXTENSION">
							<ButtonImage image={flexionImg} />
						</ButtonCustom>
						<ButtonCustom value="FLEXION">
							<ButtonImage image={extensionImg} />
						</ButtonCustom>
					</RadioCustom>
				</Form.Item>
				<Form.Item
					label={I18n.get('Degrees')}
					name="input_right_wrist_angle"
					rules={[
						{
							required: true,
							message: false
						}
					]}
				>
					<InputNumber
						style={numberInputStyle}
						min={0}
						max={90}
						decimalSeparator={getDecimalSeparator()}
						step={0.01}
						placeholder={0.0}
					/>
				</Form.Item>
			</SideContainer>
		</Container>
	);
};
