import React, { useContext, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row, Tabs } from 'antd';

import { AngleTimeContext } from '../../context';
import { PreviousButton } from '@/components/ui/Buttons/PreviousButton';
import { NextButton } from '@/components/ui/Buttons/NextButton';
import {
	iconStyle,
	nextBtnStyle,
	prevBtnStyle,
	tabStyle,
	TabsContainer,
	Footnote
} from './styles';
import { LineChart } from './LineChart.js';

const tabNames = {
	neck: I18n.get('Neck'),
	trunk: I18n.get('Trunk'),
	left_upper_arm: I18n.get('Left shoulder'),
	right_upper_arm: I18n.get('Right shoulder'),
	left_lower_arm: I18n.get('Left elbow'),
	right_lower_arm: I18n.get('Right elbow'),
	left_knee: I18n.get('Left knee'),
	right_knee: I18n.get('Right knee'),
	left_ankle: I18n.get('Left ankle'),
	right_ankle: I18n.get('Right ankle'),
	left_hand: I18n.get('Left hand'),
	right_hand: I18n.get('Right hand'),
	hip: I18n.get('Hip')
};

export const Charts = ({ angles, settings }) => {
	const { parts } = useContext(AngleTimeContext);

	const startingPartIndex = '0';
	const [activeTabIndex, setActiveTabIndex] = useState(startingPartIndex);

	const tabs = parts.reduce((acc, item) => {
		acc[item] = angles?.[item];
		return acc;
	}, {});

	const prevBtnDisabled = activeTabIndex === '0';
	const nextBtnDisabled = activeTabIndex === (Object.keys(tabs).length - 1).toString();

	function onSelectItem(itemIndex) {
		setActiveTabIndex(itemIndex);
	}

	function next() {
		if (activeTabIndex < Object.keys(tabs).length) {
			setActiveTabIndex((prev) => (parseInt(prev) + 1).toString());
		}
	}

	function previous() {
		if (activeTabIndex > 0) {
			setActiveTabIndex((prev) => (parseInt(prev) - 1).toString());
		}
	}

	return (
		<Row type="flex" justify="center" align="middle" gutter={[0, 14]}>
			<TabsContainer style={{ minHeight: 430 }}>
				<PreviousButton
					style={prevBtnStyle}
					iconStyle={iconStyle}
					onPrevious={previous}
					disabled={prevBtnDisabled}
				/>
				<NextButton
					onNext={next}
					style={nextBtnStyle}
					iconStyle={iconStyle}
					disabled={nextBtnDisabled}
				/>
				<Tabs
					centered
					type="card"
					size="small"
					style={tabStyle}
					onTabClick={onSelectItem}
					activeKey={activeTabIndex}
					defaultActiveKey={startingPartIndex}
				>
					{Object.keys(tabs).map((item, index) => (
						<Tabs.TabPane
							key={index}
							animated={false}
							tab={I18n.get(tabNames[item])}
						>
							<LineChart
								data={angles[item]}
								config={settings[item]}
								title={I18n.get(tabNames[item])}
							/>
						</Tabs.TabPane>
					))}
				</Tabs>
			</TabsContainer>
			<Col span={22}>
				<Footnote>
					*
					{I18n.get(
						'The results obtained from each body part can be affected by the method and position of filming, the identification of the person in the analysis can suffer interference from objects present in the room. The angle data shown are approximate values.'
					)}
				</Footnote>
			</Col>
		</Row>
	);
};
