import styled from 'styled-components';
import { Col, Form } from 'antd';

export const DurationLabel = styled.p`
	grid-area: duration-label;
`;

export const LeftDuration = styled(Form.Item)`
	grid-area: left-duration;
`;

export const RightDuration = styled(Form.Item)`
	grid-area: right-duration;
`;
export const SuffixDuration = styled.span`
	grid-area: suffix-duration;
`;

export const Warning = styled.p`
	grid-area: warning;
`;

export const GridContainer = styled(Col)`
	display: grid;
	justify-content: center;
	align-items: flex-start;
	text-align: center;
	gap: 10px 20px;
	grid-template-columns: 200px auto auto auto auto;
	grid-template-areas: 'duration-label left-duration right-duration suffix-duration warning';
	margin-top: 10px;
	min-height: 60px;

	${LeftDuration}, ${RightDuration} {
		margin: 0px;
		position: relative;
	}

	${SuffixDuration} {
		margin: -5px;
		margin-top: 5px;
		color: grey;
	}

	${DurationLabel} {
		text-align: right;
		margin: 0;
		margin-top: 5px;
	}

	${Warning} {
		text-align: center;
		margin: 0;
		margin-top: 5px;
		color: darkred;
		width: 235px;
	}

	.ant-form-item-explain.ant-form-item-explain-error {
		display: none;
	}
`;

export const PercentageDisplay = styled.div`
	position: absolute;
	overflow: hidden;
	text-overflow: ellipsis;
	top: -25px;
	width: 100%;
	height: 25px;
	font-size: 0.8rem;
	line-height: 1.5rem;
	border-radius: 5px 5px 0px 0px;
	color: ${(props) => (props.percentage > 1 ? '#5C0011' : '#092b00')};
	background: ${(props) => (props.percentage > 1 ? '#FFCCC7' : '#b7eb8f')};
`;

export const numberInputStyle = {
	width: '90px'
};
