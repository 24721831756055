

import React from 'react';
import { Col as Container, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { setOptionsReport } from '@/redux/reports/actions';


export function Conclusion() {
	const dispatch = useDispatch();
	const options = useSelector(state => state.reports.options);
	return (
		<Container sm={24}>
			<Input.TextArea
				showCount
				allowClear
				maxLength={500}
				name="conclusion"
				value={options.conclusion.text}
				disabled={!options.conclusion.checked}
				onChange={(e) => {
					dispatch(setOptionsReport({
						conclusion: {
							...options.conclusion,
							text: e.target.value
						}
					}));
				}}
				autoSize={{ minRows: 8, maxRows: 10 }}
				placeholder={I18n.get('Insert conclusion')}
			/>
		</Container>
	);
}
