import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colorsRiskDegree } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ENUM_BODY_PARTS = {
	trunk: 'Trunk',
	neck: 'Neck',
	left_lower_arm: 'Left elbow',
	right_lower_arm: 'Right elbow',
	left_upper_arm: 'Left shoulder',
	right_upper_arm: 'Right shoulder',
	left_knee: 'Left knee',
	right_knee: 'Right knee',
	left_ankle: 'Left ankle',
	right_ankle: 'Right ankle',
	left_hand: 'Left hand',
	right_hand: 'Right hand',
	hip: 'Hip',
};

export function PieChart({ data, label }) {
	return (
		<Doughnut
			plugins={[
				ChartDataLabels,
				{
					beforeDraw: (chart) => {
						const { width, height, ctx } = chart;

						ctx.restore();

						let fontSize = (height / 180).toFixed(2);
						ctx.font = fontSize + 'em sans-serif';
						ctx.textBaseline = 'middle';

						let item = I18n.get(ENUM_BODY_PARTS[label] || label);

						let lines = item.split(' ');

						if (lines.length > 1) {
							let textX = Math.round(
								(width - ctx.measureText(lines[0]).width) / 2
							);
							let textY = height / 2;

							ctx.fillText(lines[0], textX, textY - 10);

							let centerX = Math.round(
								(width - ctx.measureText(lines[1]).width) / 2
							);
							let centerY = height / 2;

							ctx.fillText(lines[1], centerX, centerY + 20);
						} else {
							let textX = Math.round(
								(width - ctx.measureText(lines[0]).width) / 2
							);
							let textY = height / 2;

							ctx.fillText(lines[0], textX, textY);
						}
						ctx.save();
					},
				},
			]}
			options={{
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					cutoutPercentage: 80,
					datalabels: {
						formatter: (value, ctx) => {
							const datapoints = ctx.chart.data.datasets[0].data;
							const total = datapoints.reduce((a, b) => a + b, 0);
							const result = parseFloat(
								(value / total) * 100
							).toFixed(1);
							return Math.trunc(result) === 0 ? '' : `${result}%`;
						},
						color: '#000',
					},
					tooltip: {
						callbacks: {
							label: (tooltipItem) => {
								let label = I18n.get(tooltipItem.label);
								let data = tooltipItem.dataset.data;
								let currentValue =
									tooltipItem.dataset.data[
										tooltipItem.dataIndex
									];
								let total = data.reduce((a, b) => a + b, 0);
								let percentage = parseFloat(
									((currentValue / total) * 100).toFixed(1)
								);
								return `${label}:${percentage}%`;
							},
						},
					},
					legend: {
						display: false,
					},
				},
			}}
			data={{
				labels: ['Safe', 'Info', 'Warning', 'Danger'].map((item) =>
					I18n.get(item)
				),
				datasets: [
					{
						data: data,
						backgroundColor: Object.keys(colorsRiskDegree).map(
							(key) => colorsRiskDegree[key].color
						),
						borderColor: Object.keys(colorsRiskDegree).map(
							(key) => colorsRiskDegree[key].border
						),
						borderWidth: 1,
					},
				],
			}}
		/>
	);
}
