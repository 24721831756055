import Types from './types';

export const next = (object) => {
	return { type: Types.NEXT_STEP, payload: object };
}

export const prev = (object) => {
	return { type: Types.PREV_STEP, payload: object };
}

export const validationStep = (step, situation) => {
	return { type: Types.VALIDATION_STEP, payload: { step, situation } };
}

export const updateInformations = (value) => {
	return { type: Types.UPDATE_INFORMATIONS_CUSTOMIZED, payload: value };
}

export const updateBodyPart = (value) => {
	return { type: Types.UPDATE_BODY_PART_CUSTOMIZED, payload: value };
}

export const setFile = (value) => {
	return { type: Types.SET_FILE, payload: value };
}

export const clearReports = () => {
	return { type: Types.CLEAR_REPORTS };
}

export const updateParameters = (value) => {
	return { type: Types.UPDATE_PARAMETERS_CUSTOMIZED, payload: value };
}

export const setOptionsReport = (value) => {
	return { type: Types.SET_OPTIONS, payload: value };
}

export const setRiskDegree = (value) => {
	return { type: Types.SET_RISK_DEGREE, payload: value };
}

export const setData = (value) => {
	return { type: Types.SET_DATA, payload: value };
}

export const setRangeRiskBands = (value) => {
	return { type: Types.SET_RANGE_RISK_BANDS, payload: value };
}

export const setRecoveryReport = (value) => {
	return { type: Types.SET_RECOVERY_REPORT, payload: value };
}
