export const tableStyle = {
	marginInline: '50px',
	marginBlock: '20px'
};

export const scoreStyle = {
	fontSize: '16px',
	fontWeight: 'bold',
	margin: 0,
	paddingInline: '8px',
	paddingBlock: '4px'
};

export const totalScoreStyle = {
	fontSize: '16px',
	fontWeight: 'bold',
	margin: 0,
	paddingInline: '10px',
	paddingBlock: '6px'
};

export const inputStyle = {
	fontSize: '14px'
};

export const suffixStyle = {
	color: 'grey'
};
