import React from 'react';
import PropTypes from 'prop-types';
import {
	Container,
	HousingContainer,
	Housing,
	Liquid,
	EmptyLiquid,
	LabelsContainer,
	Label
} from './style.js';

export function Thermometer({ value, min, max, unit = '' }) {
	const percentageFull = value / (max - min);
	const hidingThreshold = 0.1;
	const hideMax = percentageFull >= 1 - hidingThreshold;
	const hideMin = percentageFull <= hidingThreshold;

	return (
		<Container>
			<HousingContainer>
				<Housing>
					<EmptyLiquid percentage={percentageFull} />
					<Liquid />
				</Housing>
			</HousingContainer>
			<LabelsContainer>
				<Label isMax={true} hide={hideMax}>{`${max} ${unit}`}</Label>
				<Label percentage={percentageFull}>{`${value} ${unit}`}</Label>
				<Label isMin={true} hide={hideMin}>{`${min} ${unit}`}</Label>
			</LabelsContainer>
		</Container>
	);
}

Thermometer.propTypes = {
	value: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	unit: PropTypes.string
};
