import React from 'react';
import { Form } from 'antd';

import { RadioCards } from '@/components/ui/Inputs/RadioCards';
import { Container } from './styles';

import { TEMPORAL_DISTRIBUTIONS } from '../../enum';

export const TemporalDistributions = () => {
	return (
		<Container>
			<Form.Item
				name="temporal_distribution"
				rules={[
					{
						required: true,
						message: 'Invalid'
					}
				]}
				style={{ margin: 0 }}
				valuePropName="checked"
			>
				<RadioCards
					name="temporal_distribution"
					cardType="vertical"
					direction="column"
					cards={TEMPORAL_DISTRIBUTIONS}
				/>
			</Form.Item>
		</Container>
	);
};
