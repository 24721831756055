import armImage1 from '@/assets/img/ergonomic_tools/kim_mho/arm_posture_1.svg';
import armImage2 from '@/assets/img/ergonomic_tools/kim_mho/arm_posture_2.svg';
import armImage3 from '@/assets/img/ergonomic_tools/kim_mho/arm_posture_3.svg';
import armImage4 from '@/assets/img/ergonomic_tools/kim_mho/arm_posture_4.svg';
import armImage5 from '@/assets/img/ergonomic_tools/kim_mho/arm_posture_5.svg';
import armImage6 from '@/assets/img/ergonomic_tools/kim_mho/arm_posture_6.svg';
import armImage7 from '@/assets/img/ergonomic_tools/kim_mho/arm_posture_7.svg';
import armImage8 from '@/assets/img/ergonomic_tools/kim_mho/arm_posture_8.svg';

import bodyPostureImg1 from '@/assets/img/ergonomic_tools/kim_mho/body_posture_1.svg';
import bodyPostureImg2 from '@/assets/img/ergonomic_tools/kim_mho/body_posture_2.svg';
import bodyPostureImg3 from '@/assets/img/ergonomic_tools/kim_mho/body_posture_3.svg';
import bodyPostureImg4 from '@/assets/img/ergonomic_tools/kim_mho/body_posture_4.svg';

import { DANGER_INDICATORS } from './Result/RiskRange/Conditions/ConditionDetails';

export const FORCE_INTENSITIES = {
	VERY_LOW: {
		title: 'Very low',
		description:
			'Up to 15% Fmax. Button actuation, shifting, ordering, material guidance, insertion of small parts.',
		indicator: DANGER_INDICATORS.GREEN
	},
	MODERATE: {
		title: 'Moderate',
		description:
			'Up to 30% Fmax. Gripping, joining small work pieces by hand or with small tools.',
		indicator: DANGER_INDICATORS.GREEN
	},
	HIGH: {
		title: 'High',
		description:
			'Up to 50% Fmax. Turning, winding, packaging, grasping, holding or joining parts, pressing in, cutting, working with small powered hand tools.',
		indicator: DANGER_INDICATORS.YELLOW
	},
	VERY_HIGH: {
		title: 'Very high',
		description:
			'Up to 80% Fmax. Cutting involving major element of force, working with small staple guns, moving or holding parts or tools.',
		indicator: DANGER_INDICATORS.YELLOW
	},
	PEAK: {
		title: 'Peak forces',
		description:
			'More than 80% Fmax. Tightening, loosening bolts, separating, pressing in.',
		indicator: DANGER_INDICATORS.RED
	},
	POWERFUL_HITTING: {
		title: 'Powerful hitting',
		description: 'Hitting with the ball of the thumb, palm of the hand or fist.',
		indicator: DANGER_INDICATORS.RED
	}
};

export const FORCE_TRANSFERS = {
	OPTIMUM: {
		title: 'Optimum force application',
		description:
			'Working objects are easy to grip (e.g. bar-shaped, gripping grooves) / good ergonomic gripping design (grips, buttons, tools).',
		indicator: DANGER_INDICATORS.GREEN
	},
	RESTRICTED: {
		title: 'Restricted force application',
		description: 'Greater holding forces required / no shaped grips.',
		indicator: DANGER_INDICATORS.YELLOW
	},
	HINDERED: {
		title: 'Force application considerably hindered',
		description:
			'Working objects hardly possible to grip (slippery, soft, sharp edges) / no or only unsuitable grips.',
		indicator: DANGER_INDICATORS.RED
	}
};

export const ARM_POSTURES = {
	GOOD: {
		image1: armImage1,
		image2: armImage2,
		title: 'Good',
		description:
			'Position or movements of joints in the middle (relaxed) range, only rare deviations, no continuous static arm posture, hand-arm rest possible as required.',
		indicator: DANGER_INDICATORS.GREEN
	},
	RESTRICTED: {
		image1: armImage3,
		image2: armImage4,
		title: 'Restricted',
		description:
			'Occasional positions or movements of the joints at the limit of the movement ranges, occasional long continuous static arm posture.',
		indicator: DANGER_INDICATORS.YELLOW
	},
	UNFAVOURABLE: {
		image1: armImage5,
		image2: armImage6,
		title: 'Unfavourable',
		description:
			'Frequent positions or movements of the joints at the limit of the movement ranges, frequent long continuous static arm posture.',
		indicator: DANGER_INDICATORS.YELLOW
	},
	POOR: {
		image1: armImage7,
		image2: armImage8,
		title: 'Poor',
		description:
			'Constant positions or movements of the joints at the limit of the movement ranges, constant long continuous static arm posture.',
		indicator: DANGER_INDICATORS.RED
	}
};

export const TEMPORAL_DISTRIBUTIONS = {
	GOOD: {
		title: 'Good',
		description:
			'Frequent variation of the physical workload situation due to other activities (including other types of physical workload) / without a tight sequence of higher physical workloads within one type of physical workload during a single working day.',
		indicator: DANGER_INDICATORS.GREEN
	},
	RESTRICTED: {
		title: 'Restricted',
		description:
			'Rare variation of the physical workload situation due to other activities (including other types of physical workload) / occasional tight sequence of higher physical workloads within one type of physical workload during a single working day.',
		indicator: DANGER_INDICATORS.YELLOW
	},
	UNFAVOURABLE: {
		title: 'Unfavourable',
		description:
			'No/hardly any variation of the physical workload situation due to other activities (including other types of physical workload) / frequent tight sequence of higher physical workloads within one type of physical workload during a single working day with concurrent high load peaks.',
		indicator: DANGER_INDICATORS.RED
	}
};

export const WORK_CONDITIONS = {
	GOOD: {
		title: 'Good',
		description:
			'There are no unfavourable working conditions, i.e. reliable recognition of detail / no dazzle / good climatic conditions.',
		indicator: DANGER_INDICATORS.GREEN
	},
	RESTRICTED: {
		title: 'Restricted',
		description:
			'Occasionally impaired detail recognition due to dazzle or excessively small details difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.',
		indicator: DANGER_INDICATORS.YELLOW
	},
	UNFAVOURABLE: {
		title: 'Unfavourable',
		description:
			'Frequently impaired detail recognition due to dazzle or excessively small details frequently difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.',
		indicator: DANGER_INDICATORS.RED
	}
};

export const BODY_POSTURES = {
	ALTERNATED_SITTING_STANDING: {
		image: bodyPostureImg1,
		description: [
			'Alternation between sitting and standing, alternation between standing and walking, dynamic sitting possible',
			'Trunk inclined forward only very slightly',
			'No twisting and/or lateral inclination of the trunk identifiable',
			'Head posture: variable, head not inclined backward and/or severely inclined forward or constantly moving',
			'No gripping above shoulder height / no gripping at a distance from the body'
		],
		indicator: DANGER_INDICATORS.GREEN
	},
	OCCASIONAL_WALKING: {
		image: bodyPostureImg2,
		description: [
			'Predominantly sitting or standing with occasional walking',
			'Trunk with slight inclination of the body towards the work area',
			'Occasional twisting and/or lateral inclination of the trunk identifiable',
			'Occasional deviations from good “neutral” head posture/movement',
			'Occasional gripping above shoulder height / occasional gripping at a distance from the body'
		],
		indicator: DANGER_INDICATORS.GREEN
	},
	NO_WALKING: {
		image: bodyPostureImg3,
		description: [
			'Exclusively standing or sitting without walking',
			'Trunk clearly inclined forward and/or frequent twisting and/or lateral inclination of the trunk identifiable',
			'Frequent deviations from good “neutral” head posture/movement',
			'Head posture hunched forward for detail recognition / restricted freedom of movement',
			'Frequent gripping above shoulder height / frequent gripping at a distance from the body'
		],
		indicator: DANGER_INDICATORS.YELLOW
	},
	SEVERELY_INCLINED: {
		image: bodyPostureImg4,
		description: [
			'Trunk severely inclined forward / frequent or long-lasting bending',
			'Work being carried out in a kneeling, squatting, lying position',
			'Constant twisting and/or lateral inclination of the trunk identifiable',
			'Body posture strictly fixed / visual check of action through magnifying glasses or microscopes',
			'Constant deviations from good “neutral” head posture/movement',
			'Constant gripping above shoulder height / constant gripping at a distance from the body'
		],
		indicator: DANGER_INDICATORS.RED
	}
};

export const DIAGNOSTICS = {
	LOW: {
		PHYSICAL_OVERLOAD: 'Physical overload is unlikely.',
		HEALTH_CONSEQUENCES: 'No health risk is to be expected.',
		MEASURES: 'None.'
	},
	SLIGHTLY_INCREASED: {
		PHYSICAL_OVERLOAD: 'Physical overload is possible for less resilient persons.',
		HEALTH_CONSEQUENCES:
			'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.',
		MEASURES:
			'For less resilient persons, workplace redesign and other prevention measures may be helpful.'
	},
	SUBSTANTIALLY_INCREASED: {
		PHYSICAL_OVERLOAD: 'Physical overload is possible for normally resilient persons.',
		HEALTH_CONSEQUENCES:
			'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.',
		MEASURES: 'Workplace redesign and other prevention measures should be considered.'
	},
	HIGH: {
		PHYSICAL_OVERLOAD: 'Physical overload is likely.',
		HEALTH_CONSEQUENCES:
			'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.',
		MEASURES:
			'Workplace redesign measures are necessary. Other prevention measures should be considered.'
	}
};
