import styled from 'styled-components';

export const Filler = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: ${(props) => props.percentage}%;
	background-color: ${(props) => props.bgColor};
	overflow: hidden;
`;

export const Container = styled.div`
	display: flex;
	height: 30px;
	width: 100%;
	background-color: #e0e0de;
	border-radius: 50px;
	margin: 0px;
	margin-block: 8px;
	border: 1px solid lightgray;
	overflow: hidden;
`;

export const Label = styled.span`
	color: rgba(0, 0, 0, 0.5);
	font-weight: bold;
	margin-right: 5px;
`;
