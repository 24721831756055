import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Can from '@/components/Can';
import { Report } from '@/components/Report';
import { Spinner } from '@/components/Spinner';
import { Upload } from '@/components/views/Upload';
import { Account } from '@/components/Settings/Account';
import { PreliminaryAnalysisForm } from '@/components/views/PreliminaryAnalysis/Form';

import { Reba } from '@/components/views/Report/Reba';
import { Niosh } from '@/components/views/Report/Niosh';
import { AngleTime } from '@/components/views/Report/AngleTime';
import { StrainIndex } from '@/components/views/Report/StrainIndex';
import { KimPushPull } from '@/components/views/Report/KimPushPull';
import { LibertyMutual } from '@/components/views/Report/LibertyMutual';
import { KimManualHandling } from '@/components/views/Report/KimManualHandling';

import { Ewa } from '@/components/views/Ewa';
import { Sera } from '@/components/views/Sera';
import { Plan } from '@/components/Settings/Plan';
import { JdsD92 } from '@/components/views/JdsD92';
import { BeraJob } from '@/components/views/BeraJob';
import { MergePea } from '@/components/views/MergePea';
import { EwaJdsD86 } from '@/components/views/EwaJdsD86';
import { ManageRisk } from '@/components/views/ManageRisk';
import { SeraReview } from '@/components/views/SeraReview';
import { ActionPlans } from '@/components/views/ActionPlans';
import { BeraReports } from '@/components/views/BeraReports';
import { JdsD92Reports } from '@/components/views/JdsD92Reports';
import { SeraSummaryList } from '@/components/views/SeraSummaryList';
import { EwaJdsD86Reports } from '@/components/views/EwaJdsD86Reports';
import { CustomReportsFiles } from '@/components/views/CustomReportsFiles';
import { ManageUsers, Preferences, Organizations } from '@/components/Settings';
import { SubSteps } from '@/components/views/Report/LibertyMutual/Report/SubSteps';
import { SuperPreliminaryAnalysis } from '@/components/views/SuperPreliminaryAnalysis';
import { BackCompressiveForceEstimation } from '@/components/views/Report/BackCompressiveForceEstimation';

const Dashboard = lazy(() => import('@/views/Dashboard'));

const reportRoutes = [
	{
		type: 'niosh',
		component: Niosh
	},
	{
		type: 'reba',
		component: Reba
	},
	{
		type: 'angle-time',
		component: AngleTime
	},
	{
		type: 'strain-index',
		component: StrainIndex
	},
	{
		type: 'kim-pp',
		component: KimPushPull
	},
	{
		type: 'kim-mho',
		component: KimManualHandling
	},
	{
		type: 'liberty-mutual',
		component: LibertyMutual
	},
	{
		type: 'back-compressive-force-estimation',
		component: BackCompressiveForceEstimation
	},
	{
		type: 'preliminary-ergonomic-analysis',
		component: PreliminaryAnalysisForm
	},
	{
		type: 'ewa',
		component: Ewa
	},
	{
		type: 'ewa-jds-d86',
		component: EwaJdsD86
	}
];

export function PrivateRoutes() {
	const max_users = useSelector((state) => state.plan.max_users);
	return (
		<Route
			component={() => (
				<Suspense fallback={<Spinner />}>
					<Route exact path="/" component={Dashboard} />
					<Route exact path="/upload" component={Upload} />
					<Route exact path="/reporting" component={Report} />
					<Route exact path="/settings" component={Account} />
					<Route exact path="/settings/account" component={Account} />
					<Route exact path="/action-plans" component={ActionPlans} />
					<Route exact path="/ewa/new-document" component={MergePea} />
					<Route exact path="/ewa/file-history" component={SuperPreliminaryAnalysis} />
					<Route exact path="/custom-reports/files" component={CustomReportsFiles} />
					<Route exact path="/custom-reports/bera" component={BeraReports} />
					<Route exact path="/custom-reports/bera/report/:bera_job_summary_id?" component={BeraJob} />
					<Route exact path="/custom-reports/sera" component={SeraSummaryList} />
					<Route exact path="/custom-reports/sera/report/:sera_summary_id?" component={Sera} />
					<Route exact path="/custom-reports/sera/review/:sera_summary_id?" component={SeraReview} />
					<Route exact path="/custom-reports/jds-d86" component={EwaJdsD86Reports} />
					<Route exact path="/custom-reports/jds-d92" component={JdsD92Reports} />
					<Route exact path="/custom-reports/jds-d92/report/:file_id?" component={JdsD92} />
					<Route
						exact
						path="/custom-reports/jds-d86/report/:original_custom_report_result_id?"
						component={EwaJdsD86}
					/>
					<Route
						exact
						path="/custom-reports/jds-d86/review/:original_custom_report_result_id"
						component={EwaJdsD86}
					/>

					{Can('custom-report', 'management') && (
						<Route exact path="/custom-reports/manage-risk" component={ManageRisk} />
					)}

					{reportRoutes.map((report) => (
						<Route
							key={report.type}
							component={report.component}
							path={`/reporting/${report.type}/:file_id`}
						/>
					))}
					<Route
						exact
						path="/reporting/report/liberty-mutual/:file_id/:liberty_mutual_report_id/:report_id?"
						component={SubSteps}
					/>
					{Can('settings', 'plan') && <Route exact path="/settings/plan" component={Plan} />}
					{Can('settings', 'management') && (
						<>
							{max_users > 1 && (
								<>
									<Route exact path="/settings/manage-users" component={ManageUsers} />
									<Route exact path="/settings/companies" component={Organizations} />
								</>
							)}
							<Route exact path="/settings/preferences" component={Preferences} />
						</>
					)}
				</Suspense>
			)}
		/>
	);
}
