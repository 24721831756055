import React from 'react';
import { Row, Col, Steps, Button } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

const { Step } = Steps;

const Container = styled(Col)`
	padding: 10px 0px 10px 0px;
`;

export const HeaderStep = ({ current, steps }) => (
	<Container sm={24}>
		<Steps size="small" current={current}>
			{steps.map((item) => (
				<Step key={item.title} title={I18n.get(item.title)} />
			))}
		</Steps>
	</Container>
);

export const Main = ({ children }) => <Col sm={24}>{children}</Col>;

export const Footer = ({ current, steps, onNext, onPrevious, onFinish, isFetching = false }) => (
	<Col sm={24}>
		<Row justify="end">
			<Col>
				{current > 0 && (
					<Button style={{ margin: '0 8px' }} onClick={onPrevious}>
						{I18n.get('Previous')}
					</Button>
				)}
			</Col>
			<Col>
				{current < steps.length - 1 && (
					<Button type="primary" onClick={onNext} disabled={!steps[current].is_valid}>
						{I18n.get('Next')}
					</Button>
				)}
			</Col>
			<Col>
				{current === steps.length - 1 && (
					<Button type="primary" loading={isFetching} onClick={onFinish}>
						{I18n.get('Finish')}
					</Button>
				)}
			</Col>
		</Row>
	</Col>
);
