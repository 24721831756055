import styled from 'styled-components';
import { Col } from 'antd';

export const PreviousCol = styled(Col)`
	padding: 0;

	margin-right: 1rem;
	@media (min-width: 576px) {
		margin-right: 4rem;
	}
`;

export const CustomFormContainer = styled(Col)`
	padding: 2rem 1rem;
	margin-left: 2rem;
	border-radius: 1rem;
	background-color: #f8f8f8;
`;
