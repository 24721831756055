import styled from 'styled-components';
import { Col, Form } from 'antd';

export const Container = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 50px;
`;

export const InfoContainer = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ColorScale = styled.div`
	height: 160px;
	width: 10px;
	border-radius: 5px;
	background: linear-gradient(#52c41a, #ffec3d, #f5222d);
`;

export const LabelsContainer = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	cursor: default;
	margin-left: 10px;
`;

export const Label = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 125px;
	height: 32px;
	border-radius: 2px;
	border: 1px solid #d9d9d9;
	background-color: #fff;
`;

export const Description = styled.span`
	margin-left: 14px;
`;

export const LeftHeader = styled.span`
	grid-area: left-header;
`;

export const RightHeader = styled.span`
	grid-area: right-header;
`;

export const InputLabel = styled.p`
	grid-area: input-label;
`;

export const LeftInput = styled(Form.Item)`
	grid-area: left-input;
`;

export const RightInput = styled(Form.Item)`
	grid-area: right-input;
`;

export const GridContainer = styled(Col)`
	display: grid;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	text-align: center;
	gap: 10px 20px;
	grid-template-columns: 150px auto auto;
	grid-template-areas:
		'empty left-header right-header'
		'input-label left-input right-input';

	${LeftInput}, ${RightInput} {
		margin: 0px;
	}

	${InputLabel} {
		text-align: right;
		margin: 0;
	}

	.ant-form-item-explain.ant-form-item-explain-error {
		display: none;
	}
`;

export const numberInputStyle = {
	width: '90px'
};
