import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Divider } from 'antd';

import { InputSection } from '../InputSection';
import { ArmPosture } from './ArmPosture';
import { WorkConditions } from './WorkConditions';
import { TemporalDistributions } from './TemporalDistribution';

export const Conditions = () => {
	return (
		<Row align="top" justify="center" style={{ marginBlock: 20 }}>
			<Col span={24}>
				<InputSection
					title={I18n.get('Hand/arm position and movement')}
					children={<ArmPosture />}
				/>
			</Col>
			<Divider />
			<Col span={12}>
				<InputSection
					title={I18n.get('Unfavourable working conditions')}
					children={<WorkConditions />}
				/>
			</Col>
			<Col span={12}>
				<InputSection
					title={I18n.get('Work organization / temporal distribution')}
					children={<TemporalDistributions />}
				/>
			</Col>
		</Row>
	);
};
