import { Col } from 'antd';
import styled from 'styled-components';

export const InputsContainer = styled(Col)`
	.ant-form-item-label {
		overflow: visible;
		white-space: nowrap;
		width: max-content;
		label {
			font-weight: 600;
			font-size: 0.8rem;
			overflow: visible;
			width: max-content;
			white-space: nowrap;
			max-width: max-content;

			@media (min-width: 1366px) {
				font-size: 0.9rem;
			}
		}
	}

	.ant-form-item-explain-error {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.ant-input-number,
	.ant-input-number-in-form-item {
		width: 100%;
	}
`;
