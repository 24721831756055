import styled from 'styled-components';
import { Col, Radio } from 'antd';

export const Container = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 80px;
	margin-left: 40px;
`;

export const SideContainer = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	row-gap: 10px;
`;

export const ImageContainer = styled.div`
	width: 180px;
	height: 120px;
	background-color: white;
	overflow: hidden;
`;

export const HandImage = styled.img`
	width: 100%;
	height: 100%;
`;

export const numberInputStyle = {
	width: '90px'
};

export const ButtonCustom = styled(Radio.Button)`
	border-radius: 2px;
	padding: 0;
	height: auto;
	width: auto;
	margin-bottom: 10px;
	transition: all 0.2s;

	&:hover {
		filter: brightness(0.9);
		transform: scale(1.03);
	}
`;
export const RadioCustom = styled(Radio.Group)`
	display: flex;
	flex-direction: column;
	.ant-radio-button-wrapper-checked {
		border: 2px solid #6380ff;
	}
`;
