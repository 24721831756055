import styled from 'styled-components';

export const Item = styled.label`
	display: block;
`;

export const ItemValue = styled(Item)`
	padding-top: 10px;
	font-weight: bold;
`;

export const ContainerChart = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
`;
