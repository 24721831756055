import React from 'react';
import { I18n } from '@aws-amplify/core';
import { InputNumber } from 'antd';
import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import {
	ExertionLabel,
	GridContainer,
	LeftExertion,
	LeftHeader,
	LeftObservation,
	numberInputStyle,
	ObservationLabel,
	RightExertion,
	RightHeader,
	RightObservation,
	SuffixObservation
} from './styles';

interface EffortsProps {
	onChangeFactors: () => void;
}

export const Efforts: React.FC<EffortsProps> = ({ onChangeFactors }) => (
	<GridContainer>
		<LeftHeader>{I18n.get('Left')}</LeftHeader>
		<RightHeader>{I18n.get('Right')}</RightHeader>
		<ExertionLabel>{I18n.get('Number of exertions observed')}</ExertionLabel>
		<LeftExertion
			name="input_left_exertions"
			style={{ margin: 0 }}
			rules={[
				{
					required: true,
					message: ''
				}
			]}
		>
			<InputNumber
				min={0}
				step={1}
				controls
				max={999}
				placeholder="0"
				style={numberInputStyle}
				onChange={onChangeFactors}
			/>
		</LeftExertion>
		<RightExertion
			name="input_right_exertions"
			style={{ margin: 0 }}
			rules={[
				{
					required: true,
					message: ''
				}
			]}
		>
			<InputNumber
				min={0}
				max={999}
				step={1}
				placeholder="0"
				style={numberInputStyle}
				onChange={onChangeFactors}
			/>
		</RightExertion>
		<ObservationLabel>{I18n.get('Total observation time')}</ObservationLabel>
		<LeftObservation
			name="input_left_observation_time"
			style={{ margin: 0 }}
			rules={[
				{
					required: true,
					message: ''
				}
			]}
		>
			<InputNumber
				min={0.01}
				max={999}
				step={0.01}
				placeholder="0.01"
				style={numberInputStyle}
				onChange={onChangeFactors}
				decimalSeparator={getDecimalSeparator()}
			/>
		</LeftObservation>
		<RightObservation
			name="input_right_observation_time"
			style={{ margin: 0 }}
			rules={[
				{
					required: true,
					message: ''
				}
			]}
		>
			<InputNumber
				min={0.01}
				max={999}
				step={0.01}
				placeholder="0.01"
				style={numberInputStyle}
				onChange={onChangeFactors}
				decimalSeparator={getDecimalSeparator()}
			/>
		</RightObservation>
		<SuffixObservation>{I18n.get('seconds')}</SuffixObservation>
	</GridContainer>
);
