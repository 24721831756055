import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const CardsContainer = styled.div`
	display: flex;
	${(props) =>
		props.direction === 'column'
			? 'flex-direction: column; justify-content: space-between; align-items: center;'
			: 'flex-direction: row; justify-content: center; align-items: center;'};
	width: 100%;
	gap: 20px;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: left;
	align-items: start;
`;

export const Input = styled.input`
	margin-top: 6px;
	margin-left: 10px;
	min-width: 30px;
	width: 10%;
`;

export const Title = styled.h3`
	font-weight: bold;
	width: 90%;
`;

export const Description = styled.p`
	text-align: left;
	margin: 0;
	padding-inline: 20px;
	padding-bottom: 12px;
`;

export const InfoCard = styled.div`
	display: flex;
	${(props) =>
		props.cardType === 'horizontal'
			? 'flex-direction: row; justify-content: space-between; align-items: start;'
			: 'flex-direction: column; justify-content: left; align-items: center;'};
	padding-top: 15px;
	cursor: pointer;
	border-radius: 2px;
	border: ${(props) => (props.selected ? '2px solid #1890FF' : '2px solid #e6e8e8')};
	transition: border ease 0.2s;

	${TitleContainer} {
		width: ${(props) => (props.cardType === 'horizontal' ? '40%' : '100%')};
	}

	${Description} {
		width: ${(props) => (props.cardType === 'horizontal' ? '60%' : '100%')};
	}
`;
