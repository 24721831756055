import styled from 'styled-components';
import { Col } from 'antd';

export const FormColumn = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
`;
