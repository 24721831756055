import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row, Card } from 'antd';
import { CouplingDetail } from './CouplingDetail';
import { Thermometer } from '@/components/ui/Thermometer';

const cardTitleStyle = {
	textAlign: 'center',
	paddingInline: '10px'
};

const detailCardStyle = {
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'center',
	flexDirection: 'column',
	height: '220px',
	padding: '10px'
};

const textCardStyle = {
	height: '65px',
	padding: '10px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
};

const thermometerTextStyle = {
	margin: 0,
	marginTop: '10px',
	textAlign: 'center'
};

export function NioshDetails({ formattedReport }) {
	const durationMeterValues = {
		LESS_THAN_1H: { value: 1, label: '1 hour' },
		BETWEEN_1_AND_2H: { value: 2, label: '1 - 2 hours' },
		BETWEEN_2_AND_8H: { value: 8, label: '2 - 8 hours' }
	};

	const couplingLabels = {
		POOR: 'Poor',
		FAIR: 'Fair',
		GOOD: 'Good'
	};

	return (
		<>
			<Row justify="end">
				<Col span={12}>
					<Card
						title={I18n.get('Coupling')}
						headStyle={cardTitleStyle}
						bodyStyle={detailCardStyle}
					>
						<div>
							<CouplingDetail coupling={formattedReport.coupling} />
						</div>
						<span>{I18n.get(couplingLabels[formattedReport.coupling])}</span>
					</Card>
				</Col>
				<Col span={6}>
					<Card
						title={I18n.get('Frequency')}
						headStyle={cardTitleStyle}
						bodyStyle={detailCardStyle}
					>
						<Thermometer
							min={0.2}
							max={16}
							value={parseFloat(formattedReport.frequency)}
						/>
						<p style={thermometerTextStyle}>{I18n.get('lifts / min')}</p>
					</Card>
				</Col>
				<Col span={6}>
					<Card
						title={I18n.get('Duration')}
						headStyle={cardTitleStyle}
						bodyStyle={detailCardStyle}
					>
						<Thermometer
							min={0}
							max={8}
							value={durationMeterValues[formattedReport.duration].value}
							unit="h"
						/>
						<p style={thermometerTextStyle}>
							{I18n.get(durationMeterValues[formattedReport.duration].label)}
						</p>
					</Card>
				</Col>
			</Row>
			<Row justify="end" style={{ marginBottom: '10px' }}>
				<Col span={12}>
					<Card
						style={{ borderTop: '2px dashed lightgrey' }}
						bodyStyle={textCardStyle}
					>
						<span>
							{I18n.get(`Coupling factor: `)}
							<strong>{formattedReport.coupling_factor}</strong>
						</span>
					</Card>
				</Col>
				<Col span={12}>
					<Card
						style={{ borderTop: '2px dashed lightgrey' }}
						bodyStyle={textCardStyle}
					>
						<span>
							{I18n.get(`Frequency factor: `)}
							<strong>{formattedReport.frequency_factor}</strong>
						</span>
					</Card>
				</Col>
			</Row>
		</>
	);
}
