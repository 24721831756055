import styled from 'styled-components';

export const Container = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%); /* for IE 9 */
	-webkit-transform: translate(-50%, -50%); /* for Safari */

	width: 500px;
	height: auto;
	padding: 36px;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

	button {
		width: 100%;
	}

	p {
		color: #737373;
		margin-top: 16px;
    	font-size: 19px;
		display: flex;
		justify-content: space-between;
	}
`;

export const ContainerImage = styled.div`
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 40%;
    padding-top: 3%;
    padding-bottom: 4%;
  }

  span {
    vertical-align: text-bottom;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 700;
    /* color: @primary-color; */
    /* .text-gradient(); */
  }
`;








// import styled from 'styled-components';

// export const Title = styled.h3`
//   font-size: 20px;
//   font-weight: 900;
//   letter-spacing: -0.02em;
//   line-height: 4.2;
// `;
