import React from 'react';
import { Form } from 'antd';

import { RadioImageCards } from '@/components/ui/Inputs/RadioImageCards';
import { Container } from './styles';

import { BODY_POSTURES } from '../../enum';

export const BodyPosture = () => {
	return (
		<Container>
			<Form.Item
				name="body_posture"
				rules={[
					{
						required: true,
						message: 'Invalid'
					}
				]}
				style={{ margin: 0 }}
				valuePropName="checked"
			>
				<RadioImageCards
					name="body_posture"
					cardType="vertical"
					direction="row"
					cards={BODY_POSTURES}
					imgStyle={{ height: 100, width: 'auto', borderRadius: 6 }}
				/>
			</Form.Item>
		</Container>
	);
};
