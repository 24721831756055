import React from 'react';
import styled from 'styled-components';

const Text = styled.span`
	color: ${(props) => props.color};
	font-weight: bold;
	font-size: 1.1rem;
`;

export function ResultText({ risk, value, unit = '' }) {
	const colors = {
		VERY_LOW: 'darkgreen',
		LOW: 'darkgreen',
		MODERATE: 'darkorange',
		HIGH: 'darkred',
		VERY_HIGH: 'darkred'
	};
	const valueColor = colors[risk];

	return <Text color={valueColor}>{`${value} ${unit}`}</Text>;
}
