import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Form } from 'antd';
import { Container, DurationInput, Suffix } from './styles';

export const Duration = () => {
	return (
		<Container>
			<Form.Item
				name="duration"
				rules={[
					{
						required: true,
						message: 'Invalid'
					}
				]}
				style={{ margin: 0 }}
			>
				<DurationInput min={1} max={10} step={1} />
			</Form.Item>
			<Suffix>{I18n.get('hours')}</Suffix>
		</Container>
	);
};
