import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row } from 'antd';

import { InputSection } from '../InputSection';
import { HandPosture } from './HandPosture';
import { DailyDuration } from './DailyDuration';

export const RiskFactors2 = () => {
	return (
		<Row justify="center" align="middle">
			<InputSection title={I18n.get('Hand/wrist posture')} children={<HandPosture />} />
			<InputSection
				title={I18n.get('Duration of task per day')}
				children={<DailyDuration />}
			/>
		</Row>
	);
};
