import React from 'react';
import { Form } from 'antd';

import { RadioCards } from '@/components/ui/Inputs/RadioCards';
import { Container } from './styles';

import { FORCE_TRANSFERS } from '../../enum';

export const ForceTransfer = () => {
	return (
		<Container>
			<Form.Item
				name="force_transfer"
				rules={[
					{
						required: true,
						message: 'Invalid'
					}
				]}
				style={{ margin: 0 }}
				valuePropName="checked"
			>
				<RadioCards
					name="force_transfer"
					direction="column"
					cardType="horizontal"
					cards={FORCE_TRANSFERS}
				/>
			</Form.Item>
		</Container>
	);
};
