import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { colorsRiskDegree } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ENUM_BODY_PARTS = {
	trunk: 'Trunk',
	neck: 'Neck',
	left_lower_arm: 'Left elbow',
	right_lower_arm: 'Right elbow',
	left_upper_arm: 'Left shoulder',
	right_upper_arm: 'Right shoulder',
	left_knee: 'Left leg',
	right_knee: 'Right leg',
	left_hand: 'Left hand',
	right_hand: 'Right hand',
	hip: 'Hip'
};

export function PieChart({ data, label, onClick }) {
	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			},
			cutoutPercentage: 80,
			datalabels: {
				formatter: (value, ctx) => {
					const datapoints = ctx.chart.data.datasets[0].data;
					const total = datapoints.reduce((a, b) => a + b, 0);
					const result = parseFloat((value / total) * 100).toFixed(1);
					const verify_one = Math.trunc(result) === 1 ? '' : `${result}`;
					const verify_numbers = Math.trunc(result) > 8 ? `${result}%` : verify_one;

					return Math.trunc(result) === 0 ? '' : verify_numbers;
				},
				color: '#000',
				font: {
					size: 10
				}
			},
			tooltip: {
				callbacks: {
					label: (tooltipItem) => {
						let label = I18n.get(tooltipItem.label);
						let data = tooltipItem.dataset.data;
						let currentValue = tooltipItem.dataset.data[tooltipItem.dataIndex];
						let total = data.reduce((a, b) => a + b, 0);
						let percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
						return `${label}:${percentage}%`;
					}
				}
			}
		}
	};

	const dataset = {
		labels: ['Not identified', 'Safe', 'Info', 'Warning', 'Danger'].map((item) => I18n.get(item)),
		datasets: [
			{
				data: data,
				backgroundColor: Object.keys(colorsRiskDegree).map((key) => colorsRiskDegree[key].color),
				borderColor: Object.keys(colorsRiskDegree).map((key) => colorsRiskDegree[key].border),
				borderWidth: 1
			}
		]
	};

	const CenterTitle = {
		beforeDraw: (chart) => {
			const { width, height, ctx } = chart;

			ctx.restore();

			let fontSize = (height / 200).toFixed(2);
			ctx.font = fontSize + 'em sans-serif';
			ctx.textBaseline = 'middle';

			let item = I18n.get(ENUM_BODY_PARTS[label] || label);

			let lines = item.split(' ');

			if (lines.length > 1) {
				let textX = Math.round((width - ctx.measureText(lines[0]).width) / 2);
				let textY = height / 2;

				ctx.fillText(lines[0], textX, textY - 6);

				let centerX = Math.round((width - ctx.measureText(lines[1]).width) / 2);
				let centerY = height / 2;

				ctx.fillText(lines[1], centerX, centerY + 12);
			} else {
				let textX = Math.round((width - ctx.measureText(lines[0]).width) / 2);
				let textY = height / 2;

				ctx.fillText(lines[0], textX, textY);
			}
			ctx.save();
		}
	};

	return <Doughnut onClick={onClick} plugins={[ChartDataLabels, CenterTitle]} options={options} data={dataset} />;
}
