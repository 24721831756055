import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	Container,
	CardsContainer,
	InfoCard,
	TitleContainer,
	Input,
	Description,
	Image
} from './styles';
import { I18n } from '@aws-amplify/core';

const defaultCardStyle = { width: '100%' };
const defaultImgStyle = { height: 150, width: 'auto', borderRadius: '6px' };

export function RadioImageCards({
	name,
	cards,
	cardType = 'horizontal',
	direction = 'column',
	cardStyle = defaultCardStyle,
	imgStyle = defaultImgStyle,
	...restProps
}) {
	const [selectedCard, setSelectedCard] = useState(restProps.checked ?? cards[0]);

	function onRadioSelect(event) {
		setSelectedCard(event.target.value);
		restProps.onChange(event.target.value);
	}

	function onCardSelect(value) {
		setSelectedCard(value);
		restProps.onChange(value);
	}

	return (
		<Container value={selectedCard}>
			<CardsContainer direction={direction}>
				{Object.keys(cards).map((card) => (
					<InfoCard
						key={card}
						selected={selectedCard === card}
						style={cardStyle}
						cardType={cardType}
						onClick={() => onCardSelect(card)}
					>
						<TitleContainer cardType={cardType}>
							<Input
								type="radio"
								name={name}
								id={card}
								value={card}
								checked={selectedCard === card || restProps.checked === card}
								onChange={onRadioSelect}
							/>
							<Image src={cards[card].image} style={imgStyle} />
						</TitleContainer>
						{typeof cards[card].description === 'string' ? (
							<Description cardType={cardType}>
								{I18n.get(cards[card].description)}
							</Description>
						) : (
							cards[card].description.map((item, index) => (
								<Description key={index} cardType={cardType}>
									{I18n.get(item)}
								</Description>
							))
						)}
					</InfoCard>
				))}
			</CardsContainer>
		</Container>
	);
}

RadioImageCards.propTypes = {
	name: PropTypes.string.isRequired,
	cards: PropTypes.shape({
		image: PropTypes.element,
		description: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
	}).isRequired,
	cardType: PropTypes.oneOf(['horizontal', 'vertical']),
	direction: PropTypes.oneOf(['column', 'row']),
	cardStyle: PropTypes.object,
	imgStyle: PropTypes.object
};
