import styled from 'styled-components';

interface StyledTooltipProps {
	top: number;
	left: number;
}

export const StyledTooltip = styled.div<StyledTooltipProps>`
	top: -5px;
	right: -3px;
	position: absolute;

	.ant-tooltip,
	.ant-tooltip-placement-topLeft {
		top: ${(props) => `${props.top}px !important`};
		left: ${(props) => `${props.left}px !important`};
		.ant-tooltip-content {
			width: max-content;
			.ant-tooltip-arrow {
				display: none;
			}

			.ant-tooltip-inner {
				padding: 0.5rem 1rem;
				color: #262626;
				text-align: center;
				border: 2px solid #262626;
				background-color: #f0f0f0;
				border-radius: 0.5rem 0.5rem 0.5rem 0;
			}
		}
	}
`;
