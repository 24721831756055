import React from 'react';

import { Container, Section } from './styles';
import { Gauge } from './Gauge';
import { InputParameters } from './InputParameters';
import { Diagnostics } from './Diagnostics';
import { Conditions } from './Conditions';

export function RiskRange({ report }) {
	return (
		<Container>
			<Section>
				<Gauge report={report} />
				<InputParameters report={report} />
			</Section>
			<Section>
				<Diagnostics report={report} />
			</Section>
			<Section>
				<Conditions report={report} />
			</Section>
		</Container>
	);
}
