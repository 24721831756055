import React from 'react';
import { I18n } from '@aws-amplify/core';

import { ReportTemplate } from '@/components/views/Report/ReportTemplate';
import { RiskRange } from './RiskRange';

export const Result = ({
	file,
	report,
	sectors,
	onDownloadPDF,
	isLoadingPDF,
	onChangeComment
}) => {
	const sections = [
		{
			title: I18n.get('Risk range'),
			component: <RiskRange report={report} />
		}
	];

	const isLoading = report?.isLoading || sectors?.isLoading || report.isLoading;
	const selectedSector = sectors.data.find((sector) => sector.id === file.data.sector_id);

	return (
		<ReportTemplate
			title={I18n.get('KIM - Manual Handling Operations')}
			sections={sections}
			sector={selectedSector}
			fileData={file.data}
			reportData={report.data}
			isLoading={isLoading}
			onDownloadPDF={onDownloadPDF}
			isLoadingPDF={isLoadingPDF}
			onChangeComment={onChangeComment}
		/>
	);
};
