import React, { ReactNode } from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Tooltip } from 'antd';

import { StyledTooltip } from './styles';

interface CustomIconProps {
	top: number;
	left: number;
	content: ReactNode;
	children: ReactNode;
	placement: TooltipPlacement | undefined;
}

export const CustomTooltip: React.FC<CustomIconProps> = ({
	children,
	top,
	left,
	content,
	placement,
	...restProps
}) => (
	<StyledTooltip top={top} left={left}>
		<Tooltip title={content} placement={placement} getPopupContainer={(triggerNode) => triggerNode} {...restProps}>
			{children}
		</Tooltip>
	</StyledTooltip>
);
