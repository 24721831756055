import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const Title = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	font-weight: bold;
	text-align: center;
	border-right: 2px solid lightgrey;
	padding-right: 20px;

	@media (max-width: 1340px) {
		width: 100%;
		border: 0;
		border-bottom: 2px solid lightgrey;
		padding-top: 10px;
		margin-bottom: 35px;
	}
`;

const Content = styled(Col)`
	margin-block: 5px;
	padding-inline: 20px;

	@media (max-width: 1340px) {
		margin: auto;
	}
`;

export const InputSection = ({ title, children }) => {
	return (
		<Row justify="start" style={{ marginBlock: '15px', width: 900 }}>
			<Title>{title}</Title>
			<Content>{children}</Content>
		</Row>
	);
};
