import styled from 'styled-components';
import { Col, Tag, Typography } from 'antd';
const { Text } = Typography;

export const Container = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-inline: 60px;
`;

export const GaugeContainer = styled(Col)`
	width: 150px;
`;

export const ResultLabel = styled(Tag)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	margin-top: 10px;
	width: 60px;
	height: 30px;
	font-weight: bold;
	font-size: 1.2rem;
`;

export const ResultText = styled(Text)`
	font-size: 1.2rem;
	margin-bottom: 12px;
	font-weight: bolder;
	text-align: center;
`;
