import React from 'react';
import { Row, Form, Radio } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormColumn } from './styles';
import { Measurements } from './Measurements';

export function NioshInput() {
	const couplings = [
		{
			value: 'POOR',
			label: I18n.get('Poor')
		},
		{
			value: 'FAIR',
			label: I18n.get('Fair')
		},
		{
			value: 'GOOD',
			label: I18n.get('Good')
		}
	];

	const frequencies = [0.2, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

	const durations = [
		{
			value: 'LESS_THAN_1H',
			label: I18n.get('1 hour')
		},
		{
			value: 'BETWEEN_1_AND_2H',
			label: I18n.get('1 - 2 hours')
		},
		{
			value: 'BETWEEN_2_AND_8H',
			label: I18n.get('2 - 8 hours')
		}
	];

	return (
		<Row justify="center" align="middle">
			<FormColumn md={24} lg={12} xl={10} xxl={8}>
				<Measurements />
			</FormColumn>
			<FormColumn md={24} lg={12} xl={10} xxl={8}>
				<Form.Item
					name="coupling"
					label={I18n.get('Coupling')}
					rules={[
						{
							required: true,
							message: I18n.get('Select the coupling quality')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '325px' }}>
						{couplings.map((coupling) => (
							<Radio.Button
								key={coupling.label}
								value={coupling.value}
								style={{
									width: '33%',
									textAlign: 'center'
								}}
							>
								{coupling.label}
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name="frequency"
					label={I18n.get('Lifting frequency (lifts per minute)')}
					rules={[
						{
							required: true,
							message: I18n.get('Select the lifting frequency')
						}
					]}
				>
					<Radio.Group
						buttonStyle="solid"
						size="small"
						style={{
							display: 'grid',
							textAlign: 'center',
							gridTemplateColumns: 'repeat(9, 1fr)',
							width: '325px'
						}}
					>
						{frequencies.map((frequency) => (
							<Radio.Button key={frequency} value={frequency} size>
								{frequency}
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name="duration"
					label={I18n.get('Duration')}
					rules={[
						{
							required: true,
							message: I18n.get('Select the lifting duration')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '325px' }}>
						{durations.map((duration) => (
							<Radio.Button
								key={duration.label}
								value={duration.value}
								style={{
									width: '33%',
									textAlign: 'center'
								}}
							>
								{duration.label}
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
			</FormColumn>
		</Row>
	);
}
