import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Checkbox, Form, Typography } from 'antd';

import { CONDITION_INPUT_CONSTANTS } from './util';
import { FormTypes } from './types';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const {
	UNFAVORABLE_WORKING_CONDITIONS,
	UNFAVORABLE_VEHICLE_PROPERTIES,
	REGULARLY_SIGNIFICANTLY,
	FREQUENT_STOPS_WITH_BREAKING,
	FREQUENT_STOPS_WITHOUT_BREAKING,
	MANY_CHANGES_DIRECTION,
	LOAD_MUST_POSITIONED,
	INCREASED_MOVEMENT_SPEED,
	THRE_IS_NOT,
	NO_SUITABLE_HANDLES,
	NO_BRAKE_WHEN_DRIVING,
	UNADJUSTED_CASTORS,
	DEFECTIVE_CASTORS,
	THRE_IS_NOT_TRANSPORT_DEVICES
} = CONDITION_INPUT_CONSTANTS;

const SubTitle = styled.span`
	font-weight: bold;
`;

export const Conditions: React.FC<FormTypes> = () => {
	const form = Form.useFormInstance();

	const [workingConditionsIsNone, setWorkingConditionsIsNone] = useState(true);
	const [workingConditionsOthers, setWorkingConditionsOthers] = useState(false);
	const [vehiclePropertiesIsNone, setVehiclePropertiesIsNone] = useState(true);
	const [vehiclePropertiesOthers, setVehiclePropertiesOthers] = useState(false);

	const [isFrequentStop, setIsFrequentStop] = useState(false);
	const [isNotFrequentStop, setIsNotFrequentStop] = useState(false);

	useEffect(() => {
		const workingConditions = form.getFieldsValue().working_conditions;
		const vehicleProperties = form.getFieldsValue().properties;
		const frequentStop = form.getFieldsValue().working_conditions;

		if (workingConditions?.length > 0) {
			const isFrequentStopInArray = workingConditions.includes(1);
			const isNotFrequentStopInArray = frequentStop.includes(2);
			const hasNotVehicleProperties = vehicleProperties[0] === 4;
			const hasNotConditions = workingConditions[0] === 6;

			if (hasNotConditions) {
				setWorkingConditionsIsNone(false);
				setWorkingConditionsOthers(true);
				setIsFrequentStop(true);
				setIsNotFrequentStop(true);
			}

			if (hasNotVehicleProperties) {
				setVehiclePropertiesIsNone(false);
				setVehiclePropertiesOthers(true);
			}
			if (isFrequentStopInArray) {
				setIsFrequentStop(false);
				setIsNotFrequentStop(true);
			} else if (isNotFrequentStopInArray) {
				setIsFrequentStop(true);
				setIsNotFrequentStop(false);
			}
		} else {
			setWorkingConditionsIsNone(false);
			setWorkingConditionsOthers(false);
			setVehiclePropertiesIsNone(false);
			setVehiclePropertiesOthers(false);
			setIsFrequentStop(false);
			setIsNotFrequentStop(false);
		}
	}, []);

	const conditions = [
		{
			label: I18n.get(REGULARLY_SIGNIFICANTLY),
			value: 0,
			disabled: workingConditionsOthers,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(FREQUENT_STOPS_WITH_BREAKING),
			value: 1,
			disabled: isFrequentStop,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(FREQUENT_STOPS_WITHOUT_BREAKING),
			value: 2,
			disabled: isNotFrequentStop,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(MANY_CHANGES_DIRECTION),
			value: 3,
			disabled: workingConditionsOthers,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(LOAD_MUST_POSITIONED),
			value: 4,
			disabled: workingConditionsOthers,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(INCREASED_MOVEMENT_SPEED),
			value: 5,
			disabled: workingConditionsOthers,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(THRE_IS_NOT),
			value: 6,
			disabled: workingConditionsIsNone,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		}
	];

	const properties = [
		{
			label: I18n.get(NO_SUITABLE_HANDLES),
			value: 0,
			disabled: vehiclePropertiesOthers,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(NO_BRAKE_WHEN_DRIVING),
			value: 1,
			disabled: vehiclePropertiesOthers,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(UNADJUSTED_CASTORS),
			value: 2,
			disabled: vehiclePropertiesOthers,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(DEFECTIVE_CASTORS),
			value: 3,
			disabled: vehiclePropertiesOthers,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		},
		{
			label: I18n.get(THRE_IS_NOT_TRANSPORT_DEVICES),
			value: 4,
			disabled: vehiclePropertiesIsNone,
			style: {
				marginRight: '20px',
				marginBottom: '20px',
				width: '725px'
			}
		}
	];

	const handleWorkingConditionsChange = (e: CheckboxValueType[]) => {
		const arrayEmpty = e.length === 0;
		const includesNumberOne = e.includes(1);
		const includesNumberTwo = e.includes(2);
		const includesNumberSix = e.includes(6);
		const lengthOneIncludesNumberSix = e.length === 1 && e.includes(6);
		const lengthOneNotIncludeSix = e.length === 1 && !e.includes(6);

		if (arrayEmpty) {
			setWorkingConditionsIsNone(false);
			setWorkingConditionsOthers(false);
			setIsFrequentStop(false);
			setIsNotFrequentStop(false);
		}
		if (lengthOneIncludesNumberSix) {
			setWorkingConditionsIsNone(false);
			setWorkingConditionsOthers(true);
			setIsFrequentStop(true);
			setIsNotFrequentStop(true);
		} else if (lengthOneNotIncludeSix) {
			setWorkingConditionsIsNone(true);
			setWorkingConditionsOthers(false);
			setIsFrequentStop(false);
			setIsNotFrequentStop(false);
		}

		if (includesNumberOne) {
			setIsFrequentStop(false);
			setIsNotFrequentStop(true);
		} else if (includesNumberTwo) {
			setIsFrequentStop(true);
			setIsNotFrequentStop(false);
		} else if (!includesNumberSix) {
			setIsFrequentStop(false);
			setIsNotFrequentStop(false);
		}
	};

	const handleVehiclePropertiesChange = (e: CheckboxValueType[]) => {
		const arrayEmpty = e.length === 0;
		const lengthOneIncludesNumberFour = e.length === 1 && e.includes(4);
		const lengthOneNotIncludeFour = e.length === 1 && !e.includes(4);

		if (arrayEmpty) {
			setVehiclePropertiesIsNone(false);
			setVehiclePropertiesOthers(false);
		}
		if (lengthOneIncludesNumberFour) {
			setVehiclePropertiesIsNone(false);
			setVehiclePropertiesOthers(true);
		} else if (lengthOneNotIncludeFour) {
			setVehiclePropertiesIsNone(true);
			setVehiclePropertiesOthers(false);
		}
	};

	return (
		<Row>
			<Col sm={24} style={{ paddingTop: '35px' }}>
				<Typography.Title level={5}>{I18n.get('Conditions:')}</Typography.Title>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Row
					gutter={[0, 30]}
					style={{
						marginTop: '15px',
						marginBottom: '15px',
						marginRight: '10px',
						marginLeft: '10px'
					}}
				>
					<Col sm={24}>
						<SubTitle>{I18n.get(UNFAVORABLE_WORKING_CONDITIONS)}</SubTitle>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="working_conditions"
							rules={[
								{
									required: true,
									message: I18n.get('Choose an option')
								}
							]}
						>
							<Checkbox.Group options={conditions} onChange={(e) => handleWorkingConditionsChange(e)} />
						</Form.Item>
					</Col>
					<Col sm={24}>
						<SubTitle>{I18n.get(UNFAVORABLE_VEHICLE_PROPERTIES)}</SubTitle>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="properties"
							rules={[
								{
									required: true,
									message: I18n.get('Choose an option')
								}
							]}
						>
							<Checkbox.Group options={properties} onChange={(e) => handleVehiclePropertiesChange(e)} />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
