import { Paragraph, Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Grid, Row } from 'antd';
import React from 'react';
import { ModalStyled, ModalCard } from './styles';

interface SelectEwaProps {
	visible?: boolean;
	onSelectReport: (type: string) => void;
	onClose: () => void;
}

export const SelectEwa: React.FC<SelectEwaProps> = ({ visible, onSelectReport, onClose }) => {
	const reports = [
		{
			type: 'ewa',
			title: 'NR17',
			descriptions: I18n.get('Brazilian standard')
		},
		{
			type: 'ewa-jds-d86',
			title: 'NR17 + JDS-D86',
			descriptions: I18n.get('Brazilian standard and John Deere guidelines')
		}
	];

	const breakpoints = Grid.useBreakpoint();

	return (
		<ModalStyled width={600} footer={null} open={visible} onCancel={onClose} centered>
			<Row justify="center">
				<Col span={24}>
					<Title level={2} style={{ textAlign: 'center' }}>
						{I18n.get('Ergonomic workplace analysis')}
					</Title>
				</Col>
				<Col span={24}>
					<Paragraph className="paragraph-modal" style={{ fontSize: '20px', textAlign: 'center' }}>
						{I18n.get('Select the right model for your analysis')}
					</Paragraph>
				</Col>
			</Row>

			<Row justify="center" gutter={[20, 0]}>
				{reports.map((item, index) => (
					<Col span={10} key={item.title} onClick={() => onSelectReport(item.type)}>
						<ModalCard>
							<Title ellipsis={!breakpoints.sm} level={4} className={`card-report-${index}`}>
								{item.title}
							</Title>
							<Paragraph ellipsis={!breakpoints.sm}>{item.descriptions}</Paragraph>
						</ModalCard>
					</Col>
				))}
			</Row>
		</ModalStyled>
	);
};
