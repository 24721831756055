import React from 'react';
import { Row, Form, Radio } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormColumn } from './styles';

export function Characteristics({ reportType }) {
	const gender = [
		{
			value: 'MALE',
			label: I18n.get('Male')
		},
		{
			value: 'FEMALE',
			label: I18n.get('Female')
		}
	];

	const age = [
		{
			value: 'LESS_THAN_20',
			label: I18n.get('< 20')
		},
		{
			value: 'BETWEEN_20_AND_45',
			label: I18n.get('20 to 45')
		},
		{
			value: 'MORE_THAN_45',
			label: I18n.get('> 45')
		}
	];

	const workers = [
		{
			value: 1,
			label: I18n.get('One')
		},
		{
			value: 2,
			label: I18n.get('Two')
		},
		{
			value: 3,
			label: I18n.get('Three')
		}
	];

	const hands = [
		{
			value: 1,
			label: I18n.get('One hand')
		},
		{
			value: 2,
			label: I18n.get('Both hands')
		}
	];

	return (
		<Row align="middle" justify="center" style={{ marginTop: 30 }}>
			<FormColumn md={24} lg={12} xl={10} xxl={8}>
				<Form.Item
					name="gender"
					label={I18n.get('Gender of the worker')}
					rules={[
						{
							required: reportType === 'ISO_11228',
							message: I18n.get('Select the gender')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '325px' }}>
						{gender.map((gender) => (
							<Radio.Button
								key={gender.label}
								value={gender.value}
								style={{
									width: '50%',
									textAlign: 'center'
								}}
							>
								{gender.label}
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name="age"
					label={I18n.get('Age')}
					rules={[
						{
							required: reportType === 'ISO_11228',
							message: I18n.get('Select the age')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '325px' }}>
						{age.map((age) => (
							<Radio.Button
								key={age.label}
								value={age.value}
								style={{
									width: '33.3%',
									textAlign: 'center'
								}}
							>
								{age.label}
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
			</FormColumn>
			<FormColumn md={24} lg={12} xl={10} xxl={8}>
				<Form.Item
					name="workers"
					label={I18n.get('How many workers are lifting the object?')}
					rules={[
						{
							required: true,
							message: I18n.get('Select the number of workers involved')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '325px' }}>
						{workers.map((quantity) => (
							<Radio.Button
								key={quantity.label}
								value={quantity.value}
								style={{
									width: '33.3%',
									textAlign: 'center'
								}}
							>
								{quantity.label}
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name="hands"
					label={I18n.get('Which hands are used?')}
					rules={[
						{
							required: true,
							message: I18n.get('Select which hands are used')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '325px' }}>
						{hands.map((quantity) => (
							<Radio.Button
								key={quantity.label}
								value={quantity.value}
								style={{
									width: '50%',
									textAlign: 'center'
								}}
							>
								{quantity.label}
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
			</FormColumn>
		</Row>
	);
}
