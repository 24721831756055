import styled from 'styled-components';

// background: ${({ color }) => {
// 	return color && `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
// }};`;

export const ButtonColor = styled.div`
	padding: 8px;
	background: #fff;
	border-radius: 1px;
	box-Shadow: 0 0 0 1px rgba(0,0,0,.1);
	display: inline-block;
	cursor: pointer;
`;

export const ColorSelect = styled.div`
	width: 36px;
	height: 14px;
	border-radius: 2px;
	background: ${props => props.color}
`;

export const PopoverColor = styled.div`
	position: absolute;
	z-index: 2;
`;

export const CoverColor = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
`;
