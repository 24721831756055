import React, { ReactNode, useState, useEffect } from 'react';
import { Col, Divider, Row, Skeleton, Form, TreeDataNode } from 'antd';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import type { ConsolidateReportDTO, CreateCommentsDTO, SubStepKeyResult, DownloadPDFDTO, StepResult } from '@/hooks';
import { StepKeysResultsProvider } from './components/Steps/StepKeyDescription/context';
import { ButtonsCustom } from '@/components/views/Report/ReportTemplate/ButtonsCustom';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { ReportTemplate } from '@/components/views/Report/ReportTemplate';
import { useApplicationContext } from '@/context/v1/Application/context';
import * as C from '@/components/CustomReports/Results';
import { StepsDescription } from './components/Steps';
import { useEwaContext } from '../../../context';
import { RebaResults } from './components/Reba';
import { Title } from '@/components/Typography';
import { PreviousCol } from '../../styles';
import { PDFSections } from '@/types';
import { useFile } from '@/hooks/v1';
import {
	useUpdateCustomReportResultComment,
	useConsolidateCustomReport,
	useDownloadCustomReportPDF,
	useGetCustomReportResults
} from '@/hooks';

type Sections = {
	id: string;
	title: string;
	component: ReactNode;
};

type SectionsMapper = {
	[key: string]: Sections;
};

const { useFormInstance } = Form;

export const Results: React.FC = () => {
	const history = useHistory();
	const form = useFormInstance();

	const { organization, company } = useApplicationContext();
	const { handlePreviousStep, ewa, customReport } = useEwaContext();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const initialValues: PDFSections[] = [
		PDFSections.REBA,
		PDFSections.NIOSH,
		PDFSections.KIM_PP,
		PDFSections.KIM_MHO,
		PDFSections.KIM_MHO,
		PDFSections.STRAIN_INDEX,
		PDFSections.ACTION_PLANS,
		PDFSections.NOT_EVALUATED,
		PDFSections.WORK_CONDITIONS,
		PDFSections.CHARACTERISTICS
	];

	const { data: ewaResult, isLoading: resultLoading } = useGetCustomReportResults({
		file_id: ewa?.file_id,
		company_id: company?.id,
		report_id: customReport.id,
		organization_id: organization?.id,
		selected_pdf_sections: initialValues,
		custom_report_result_id: ewa.result_id
	});

	const {
		file: { data: fileInformations, isLoading: gettingFile }
	} = useFile({
		fileId: ewa.file_id,
		companyId: company?.id,
		organizationId: organization?.id
	});

	const { mutateAsync: downloadPDF, isLoading: downloadPDFLoading } = useDownloadCustomReportPDF();
	const { mutateAsync: updateComments, isLoading: commentsLoading } = useUpdateCustomReportResultComment();
	const { mutateAsync: consolidateReport, isLoading: consolidateReportLoading } = useConsolidateCustomReport();

	const loading = resultLoading || commentsLoading || downloadPDFLoading || consolidateReportLoading || gettingFile;
	const browserLanguage = window.navigator.language ?? 'en-US';
	const isReportConsolidated = ewaResult.consolidated;

	function handleNextStep(): void {
		if (!isReportConsolidated) {
			return setIsModalOpen(true);
		}
		history.push('/reporting');
	}

	function hasErgonomicTool(nameSection: string): boolean {
		return !!ewaResult.tools_to_show.find((tool) => tool === nameSection);
	}

	function hasActionPlan(nameSection: string) {
		return nameSection === 'action_plans' && !!ewaResult?.action_plans;
	}

	function mapSectionArray(steps: StepResult[]) {
		const initialMapper: SectionsMapper = {
			informations: {
				id: ewaResult?.informations?.id,
				title: I18n.get('Results'),
				component: (
					<C.FinalResults
						worstScore={ewaResult?.informations?.sum_score}
						worstValues={ewaResult?.informations?.worst_values}
					/>
				)
			},
			...(ewaResult?.work_conditions && {
				work_conditions: {
					id: ewaResult?.work_conditions?.id,
					title: I18n.get('Working conditions'),
					component: <C.WorkConditions workConditions={ewaResult?.work_conditions} />
				}
			}),
			...(ewaResult?.characteristics && {
				characteristics: {
					id: ewaResult?.characteristics?.id,
					title: I18n.get('Characteristics'),
					component: <C.Characteristics characteristics={ewaResult?.characteristics} />
				}
			})
		};

		const toolsMapper: SectionsMapper = {
			niosh: {
				title: '',
				id: ewaResult?.niosh?.id,
				component: <C.NioshResults data={ewaResult?.niosh} />
			},
			kim_mho: {
				title: '',
				id: ewaResult?.kim_mho?.id,
				component: <C.KimManualHandlingResults data={ewaResult?.kim_mho} />
			},
			kim_pp: {
				title: '',
				id: ewaResult?.kim_pp?.id,
				component: <C.KimPushPullResults data={ewaResult?.kim_pp} />
			},
			strain_index: {
				title: '',
				id: ewaResult?.strain_index?.id,
				component: <C.StrainIndexResults data={ewaResult?.strain_index} />
			},
			reba: {
				title: '',
				id: ewaResult?.reba?.id,
				component: <RebaResults reba={ewaResult?.reba} />
			},
			action_plans: {
				title: '',
				id: uuidv4(),
				component: <C.ActionPlans data={ewaResult?.action_plans} />
			}
		};

		const reportOrder = [
			'informations',
			'work_conditions',
			'characteristics',
			'niosh',
			'kim_mho',
			'kim_pp',
			'strain_index',
			'reba',
			'action_plans'
		];

		const sections = [] as Sections[];

		reportOrder.forEach((nameSection: string) => {
			if (initialMapper[nameSection]) {
				sections.push(initialMapper[nameSection]);
			}
		});

		steps.forEach((step) => {
			sections.push({
				id: uuidv4(),
				title: I18n.get(step.description),
				component: (
					<>
						<Divider
							type="vertical"
							style={{
								width: '4px',
								border: 'none',
								height: '20px',
								borderRadius: '2px',
								margin: '0 0 0 52px',
								backgroundColor: '#e6e8e8'
							}}
						/>
						{renderStepDescription(step)}
					</>
				)
			});
		});

		reportOrder.forEach((nameSection: string) => {
			if (toolsMapper[nameSection] && (hasActionPlan(nameSection) || hasErgonomicTool(nameSection))) {
				sections.push(toolsMapper[nameSection]);
			}
		});

		return sections;
	}

	function renderStepDescription(step: StepResult): React.ReactNode {
		return step.step_keys.map((stepKey, index, stepKeys) => {
			if (hasSubStepKeyResult(stepKey.sub_step_keys)) {
				return mapSubStepKeys(stepKey.sub_step_keys);
			}
			return (
				<StepKeysResultsProvider key={stepKey.id} stepKey={stepKey}>
					<StepsDescription isLastStepKey={stepKeys.length - 1 === index} />
				</StepKeysResultsProvider>
			);
		});
	}

	function mapSubStepKeys(subStepKeys: SubStepKeyResult[]): JSX.Element[] {
		return subStepKeys.map((subStepKey, index) => (
			<StepKeysResultsProvider key={subStepKey.id} stepKey={subStepKey}>
				<StepsDescription isLastStepKey={subStepKeys.length - 1 === index} />
			</StepKeysResultsProvider>
		));
	}

	function hasSubStepKeyResult(subStepKey: SubStepKeyResult[] | undefined): subStepKey is SubStepKeyResult[] {
		return !!subStepKey && subStepKey.length > 0;
	}

	async function updateComment(text: string): Promise<void> {
		const parameters: CreateCommentsDTO = {
			comment: text,
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_result_id: ewa.result_id
		};

		await updateComments(parameters);
	}

	async function onConsolidatePDF(): Promise<void> {
		const parameters: ConsolidateReportDTO = {
			locale: browserLanguage,
			company_id: company?.id,
			report_id: ewa.id,
			file_id: ewa.file_id,
			organization_id: organization?.id,
			custom_report_result_id: ewa?.result_id
		};
		await consolidateReport(parameters);
	}

	async function handleDownloadPDF(file: any): Promise<void> {
		const parameters: DownloadPDFDTO = {
			report_id: ewa.id,
			company_id: company?.id,
			locale: browserLanguage,
			file_id: ewa.file_id,
			organization_id: organization?.id,
			selected_pdf_sections: file.pdf_custom,
			custom_report_result_id: ewa?.result_id
		};
		await downloadPDF(parameters);
	}

	async function handleChangeComment(event: React.ChangeEvent<HTMLTextAreaElement>) {
		setComment(event.target.value);
	}

	const setComment = _.debounce(async (value) => {
		await updateComment(value);
	}, 1200);

	const comment = ewaResult?.comment;

	useEffect(() => {
		form.setFieldValue('comment', comment);
	}, [comment]);

	if (resultLoading || gettingFile) {
		return <Skeleton active />;
	}

	const sections = mapSectionArray(ewaResult?.steps);

	const treeData: TreeDataNode[] = [
		{
			selectable: false,
			key: 'work_conditions',
			style: { fontWeight: 'bold' },
			title: I18n.get('Working conditions')
		},
		{
			selectable: false,
			key: 'characteristics',
			style: { fontWeight: 'bold' },
			title: I18n.get('Characteristics')
		},
		{
			selectable: false,
			key: 'not_evaluated',
			style: { fontWeight: 'bold' },
			title: I18n.get('Criteria not evaluated')
		},
		{
			title: I18n.get('Tools'),
			key: 'tools',
			selectable: false,
			style: { fontWeight: 'bold' },
			switcherIcon: <></>,
			children: [
				{
					selectable: false,
					key: 'niosh',
					title: I18n.get('Cargo Handling (NIOSH)')
				},
				{
					selectable: false,
					key: 'kim_mho',
					title: I18n.get('Manual Handling (KIM)')
				},
				{
					selectable: false,
					key: 'reba',
					title: I18n.get('Movement by score (Kinebot/REBA)')
				},
				{
					selectable: false,
					key: 'kim_pp',
					title: I18n.get('Push and Pull (KIM)')
				},
				{
					selectable: false,
					key: 'strain_index',
					title: I18n.get('Revised Strain Index (RSI)')
				}
			]
		},
		{
			selectable: false,
			key: 'action_plans',
			style: { fontWeight: 'bold' },
			title: I18n.get('Action plans')
		}
	];

	return (
		<Row justify={'center'}>
			<Col lg={21} xl={22} xxl={22}>
				<Row justify={'space-between'}>
					<Col>
						<Title level={4} style={{ marginBottom: '2.5rem' }}>
							Result
						</Title>
					</Col>
					<Col>
						<ButtonsCustom
							hasCustomModal
							hasConsolidated
							isLoadingPDF={loading}
							fileData={fileInformations}
							onDownloadPDF={handleDownloadPDF}
							onConsolidatePDF={onConsolidatePDF}
							consolidated={isReportConsolidated}
							pdfProps={{ initialValues, treeData }}
						/>
					</Col>
				</Row>
				<ReportTemplate
					hasConsolidated
					sections={sections}
					isLoadingPDF={loading}
					isLoading={gettingFile}
					fileData={fileInformations}
					onDownloadPDF={handleDownloadPDF}
					sector={fileInformations?.sector}
					onConsolidatePDF={onConsolidatePDF}
					consolidated={isReportConsolidated}
					onChangeComment={handleChangeComment}
					pdfProps={{ initialValues, treeData }}
					workstation={fileInformations?.workstation}
					title={I18n.get('Preliminary Ergonomic Analysis')}
					reportData={{ jds_d86_report: { ...ewaResult.informations } }}
				/>
			</Col>
			<Col span={24}>
				<Row>
					<Col xs={24} style={{ marginTop: '2rem' }}>
						<Row justify="center" align="middle" gutter={[0, 8]}>
							{!isReportConsolidated && (
								<PreviousCol>
									<CustomButton
										size="large"
										type="default"
										disabled={loading}
										onClick={handlePreviousStep}
									>
										{I18n.get('Previous')}
									</CustomButton>
								</PreviousCol>
							)}
							<Col>
								<CustomButton
									size="large"
									type="primary"
									htmlType="submit"
									loading={loading}
									disabled={loading}
									onClick={handleNextStep}
								>
									{I18n.get('Close')}
								</CustomButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<C.ConsolidatedModal okUrl="/reporting" isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
		</Row>
	);
};
