import styled from 'styled-components';
import { Col, InputNumber } from 'antd';

export const Container = styled(Col)`
	display: flex;
	justify-content: start;
	align-items: start;
	width: 210px;

	@media (min-width: 1600) {
		width: 230px;
	}
`;

export const FrequencyInput = styled(InputNumber)`
	width: 55px;
`;

export const Suffix = styled.span`
	margin-left: 6px;
	margin-top: 8px;
	white-space: nowrap;
	color: grey;
`;
