import styled from 'styled-components';
import { Col, InputNumber } from 'antd';

export const Container = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: start;
	width: 300px;
`;

export const DurationInput = styled(InputNumber)`
	width: 55px;
`;

export const Suffix = styled.span`
	margin-left: 6px;
	margin-top: 8px;
	color: grey;
`;
