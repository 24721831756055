import React from 'react';
import { Col, Row } from 'antd';

import { Frequency, HorizontalDistance, InitialForce, SustainedForce, TaskImage, VerticalHandHeight } from './Fields';

export const PushAndPullForm = () => {
	return (
		<Col xs={24}>
			<Row justify="space-between" align="middle">
				<Col xs={6}>
					<TaskImage width="90%" />
				</Col>
				<Col xs={24} lg={16}>
					<Row>
						<Col xs={24}>
							<Frequency />
						</Col>
					</Row>
					<Row justify="space-between">
						<Col xs={24} lg={10}>
							<Row>
								<Col xs={24} lg={16}>
									<InitialForce />
								</Col>
							</Row>
						</Col>
						<Col xs={24} lg={10}>
							<Row>
								<Col xs={24} lg={16}>
									<SustainedForce />
								</Col>
							</Row>
						</Col>
					</Row>
					<Row justify="space-between">
						<Col xs={24} lg={10}>
							<Row>
								<Col xs={24} lg={16}>
									<HorizontalDistance />
								</Col>
							</Row>
						</Col>
						<Col xs={24} lg={10}>
							<Row>
								<Col xs={24} lg={16}>
									<VerticalHandHeight />
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
