import React from 'react';
import { I18n } from '@aws-amplify/core';

export function References() {
	return (
		<div style={{ paddingTop: '10px', paddingBottom: '20px' }}>
			<p>
				(*){' '}
				{I18n.get(
					"Approximated values, depends mainly on the filming methods. In case there is no data, the algorithm didn't find the points to determine the angle, please review the filming method used."
				)}
			</p>
			<p>
				(*) {I18n.get('Reference')} - MCATAMNEY, L.; CORLETT, E. N. RULA
				– a survey method for the investigation of work-related upper
				limb disorders. Applied Ergonomics; 1993.
				https://doi.org/10.1016/0003-6870(93)90080-S
			</p>
		</div>
	);
}
