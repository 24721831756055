import React from 'react';
import { I18n } from '@aws-amplify/core';

import { RiskRange } from './RiskRange';
import { Details } from './Details';
import { ReportTemplate } from '@/components/views/Report/ReportTemplate';

export const Result = ({ file, report, sectors, onDownloadPDF, isLoadingPDF, onChangeComment }) => {
	const sections = [
		{
			title: I18n.get('Results'),
			component: <RiskRange reportData={report.data} />
		},
		{
			component: <Details reportData={report.data} />
		}
	];

	const isLoading = report?.isLoading || sectors?.isLoading || !report;
	const selectedSector = sectors.data.find((sector) => sector.id === file.data.sector_id);

	return (
		<ReportTemplate
			title={I18n.get('KIM - Push and pull')}
			sections={sections}
			sector={selectedSector}
			fileData={file.data}
			reportData={report.data}
			isLoading={isLoading}
			onDownloadPDF={onDownloadPDF}
			isLoadingPDF={isLoadingPDF}
			onChangeComment={onChangeComment}
		/>
	);
};
