import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col } from 'antd';

import { InputSection } from '../InputSection';
import { BodyPosture } from './BodyPosture';

export const Posture = () => {
	return (
		<Row align="middle" justify="start" style={{ marginBlock: 20 }}>
			<Col span={24}>
				<InputSection
					title={I18n.get('Body posture/movement')}
					children={<BodyPosture />}
				/>
			</Col>
		</Row>
	);
};
