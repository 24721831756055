import styled from 'styled-components';

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;
`;

export const Section = styled.section`
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
`;
