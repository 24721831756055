import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import highRiskIcon from '@/assets/icons/high-risk.svg';
import mediumRiskIcon from '@/assets/icons/medium-risk.svg';
import lowRiskIcon from '@/assets/icons/low-risk.svg';

const Gauge = styled.img`
	width: 100%;
`;

export function ClassificationGauge({ classification }) {
	const icons = {
		low: lowRiskIcon,
		medium: mediumRiskIcon,
		high: highRiskIcon
	};

	return <Gauge src={icons[classification]} alt="" />;
}

ClassificationGauge.propTypes = {
	classification: PropTypes.oneOf(['low', 'medium', 'high']).isRequired
};
