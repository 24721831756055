import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Item, ItemValue } from './styles';

export const FileData = ({ originalName, duration, size }) => (
	<Col sm={24} style={{ paddingTop: '20px' }}>
		<Row>
			<Col sm={24}>
				<h3>{I18n.get('File data')}</h3>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('File')}</Item>
				<ItemValue>{originalName}</ItemValue>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('Duration')}</Item>
				<ItemValue>{duration}</ItemValue>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('Size')}</Item>
				<ItemValue>{size}</ItemValue>
			</Col>
		</Row>
	</Col>
);
