import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row, Descriptions } from 'antd';

import { Measurements } from '../../Measurements';
import { NioshDetails } from './NioshDetails';
import { ResultText } from './ResultText';

export function Diagnostics({ formattedReport }) {
	const riskLabels = {
		VERY_LOW: { text: 'Very low risk', color: 'darkgreen' },
		LOW: { text: 'Low risk', color: 'darkgreen' },
		MODERATE: { text: 'Moderate risk', color: 'darkorange' },
		HIGH: { text: 'High risk', color: 'darkred' },
		VERY_HIGH: { text: 'Very high risk', color: 'darkred' }
	};
	const reportRisk = riskLabels[formattedReport.risk];

	return (
		<Row style={{ marginBottom: '20px' }}>
			<Col
				span={12}
				style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<Measurements readOnly={true} />
			</Col>
			<Col span={12}>
				<NioshDetails formattedReport={formattedReport} />

				<Descriptions bordered size="small" column={1}>
					<Descriptions.Item label={I18n.get('Recommended Weight Limit (RWL)')}>
						<ResultText
							risk={formattedReport.risk}
							value={formattedReport.recommended_weight_limit}
							unit="kg"
						/>
					</Descriptions.Item>
					<Descriptions.Item label={I18n.get('Lifting Index (LI)')}>
						<ResultText
							risk={formattedReport.risk}
							value={formattedReport.lifting_index}
						/>
					</Descriptions.Item>
					<Descriptions.Item label={I18n.get('Risk')}>
						<ResultText
							risk={formattedReport.risk}
							value={I18n.get(reportRisk.text)}
						/>
					</Descriptions.Item>
				</Descriptions>
			</Col>
		</Row>
	);
}
