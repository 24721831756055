import styled from 'styled-components';
import { Col, Form } from 'antd';

export const LeftHeader = styled.span`
	grid-area: left-header;
`;

export const RightHeader = styled.span`
	grid-area: right-header;
`;

export const ExertionLabel = styled.p`
	grid-area: exertion-label;
`;

export const ObservationLabel = styled.p`
	grid-area: observation-label;
`;

export const LeftExertion = styled(Form.Item)`
	grid-area: left-exertion;
`;

export const RightExertion = styled(Form.Item)`
	grid-area: right-exertion;
`;

export const LeftObservation = styled(Form.Item)`
	grid-area: left-observation;
`;

export const RightObservation = styled(Form.Item)`
	grid-area: right-observation;
`;

export const SuffixObservation = styled.span`
	grid-area: suffix-observation;
`;

export const GridContainer = styled(Col)`
	display: grid;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 10px 20px;
	grid-template-columns: 200px auto auto auto;
	grid-template-areas:
		'. left-header right-header .'
		'exertion-label left-exertion right-exertion .'
		'observation-label left-observation right-observation suffix-observation';

	${ExertionLabel}, ${ObservationLabel} {
		text-align: right;
		margin: 0;
	}

	${SuffixObservation} {
		margin-left: -5px;
		color: grey;
	}

	.ant-form-item-explain.ant-form-item-explain-error {
		display: none;
	}
`;

export const numberInputStyle = {
	width: '90px'
};
