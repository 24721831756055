import styled from 'styled-components';
import { Col } from 'antd';

export const Container = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	gap: 20px;
`;

export const Parameter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 600px;
`;

export const Label = styled.span`
	font-size: 14px;
	font-weight: bold;
`;

export const Value = styled.p`
	font-size: 14px;
	margin: 0;
	width: 40%;
`;
