import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const TitleContainer = styled(Col)`
	display: flex;
	justify-content: start;
	align-items: center;
	font-weight: bold;
	text-align: left;
	margin-left: 20px;
	margin-right: 25px;
	margin-bottom: ${(props) => (props.$inline ? 0 : '30px')};

	@media (min-width: 1600) {
		margin-right: 50px;
	}
`;

const Title = styled.h2`
	margin: 0;
	font-size: 17px;
	font-weight: bold;
`;

const Content = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const InputSection = ({ title, children, inline = false }) => {
	return (
		<Row justify="start" style={{ marginBlock: '15px' }}>
			<Col span={inline ? null : 24} style={{ display: 'flex', alignItems: 'center' }}>
				<TitleContainer $inline={inline}>
					<Title>{title}</Title>
				</TitleContainer>
			</Col>
			<Col span={inline ? null : 24}>
				<Content>{children}</Content>
			</Col>
		</Row>
	);
};
