import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import type { CustomReport, Response } from '@/types';
import type { BaseContext } from '@/types';
import Api from '@/services/api';

export type GetCustomReportByNameDTO = BaseContext & {
	report_name: string;
};

export type GetCustomReportByNameResponse = Pick<CustomReport, 'id' | 'name' | 'description' | 'acronym'>;

async function getCustomReportByName(params: GetCustomReportByNameDTO): Promise<GetCustomReportByNameResponse> {
	const url = `/custom-report/name/${params.report_name}`;
	const { data } = await Api.get<GetCustomReportByNameResponse>(url, {
		params
	});

	return data;
}

export const useGetCustomReportByName = ({ organization_id, company_id, report_name }: GetCustomReportByNameDTO) => {
	const params = { organization_id, company_id, report_name };
	const report = useQuery<GetCustomReportByNameResponse, AxiosError<Response>>(
		['jds_d86_report', params],
		() => getCustomReportByName(params),
		{
			enabled: !!organization_id && !!company_id && !!report_name,
			retry: 0
		}
	);
	return {
		...report,
		data: report.data ?? ({} as GetCustomReportByNameResponse)
	};
};
